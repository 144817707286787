<h1 mat-dialog-title>Confirm</h1>
<div mat-dialog-content>
  <p>Are you sure you want to mark this isolation as failed?</p>
  <mat-form-field class="full-width">
    <textarea resize="none" matInput  placeholder="Please enter comment" [formControl]="commentControl" required></textarea>
    <mat-error *ngIf="commentControl.hasError('required')">Comment is required</mat-error>
  </mat-form-field>
</div>
<mat-dialog-actions class="actions" align="end">
  <button mat-button class="button button--muted button--xs" (click)="onCancelClick()">Cancel</button>
  <button mat-button class="button button--danger button--xs" [disabled]="!commentControl.valid" (click)="confirm()">Yes</button>
</mat-dialog-actions>

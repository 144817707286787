import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IsolationService } from 'src/app/map/isolations/isolation.service';

@Component({
  selector: 'app-breach-rejected',
  templateUrl: './breach-rejected.component.html',
  styleUrls: ['./breach-rejected.component.css']
})
export class BreachRejectedComponent implements OnInit {



  constructor(private route: ActivatedRoute, private isolationsService: IsolationService) {


  }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      if (params["id"]) {
        this.isolationsService.rejectBreachResolution(params["id"]).subscribe();
      }
    })

  }





}

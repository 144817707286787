import { Injectable, Type, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SignalrService } from 'src/app/shared/services/signalr.service';
import { ToastService } from 'src/app/shared/services/toast.service';


export type Notification = {
id: string;
type: 'BREACH' | 'RESOLUTION'
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

   public itemsSubject$: BehaviorSubject<Notification[]> = new BehaviorSubject([]);

  constructor() {
  }

  addItem(item: Notification): void {
    const currentItems = this.itemsSubject$.getValue();
    currentItems.unshift(item);
    this.itemsSubject$.next(currentItems);
  }

  removeItem(item: Notification): void {
    const currentItems = this.itemsSubject$.getValue();
    const updatedItems = currentItems.filter(i => i !== item);
    this.itemsSubject$.next(updatedItems);
  }
}

<form [formGroup]="form" class="isolation-form">
  <mat-card>
    <mat-card-content>
      <mat-form-field appearance="outline">
        <mat-label>User</mat-label>
        <mat-select hasRole [enableIfHasRole]="['ADMINISTRATORS']" formControlName="userID">
          <mat-option *ngFor="let user of userService.getState() | async" [value]="user.userID">
            {{user.firstName}} {{user.lastName}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Grower</mat-label>
        <mat-select formControlName="growerID">
          <mat-option value="" disabled>Choose a Grower</mat-option>
          <mat-option *ngFor="let growers of growerService.getState() | async" [value]="growers.growerID">
            {{growers.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Season</mat-label>
        <mat-select formControlName="seasonID">
          <mat-option value="" disabled>Choose a Season</mat-option>
          <mat-option *ngFor="let season of seasonService.getState() | async" [value]="season.seasonID">
            {{season.startDate | displayDate}} - {{season.endDate | displayDate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card-content>
  </mat-card>

  <mat-card class="date-container">
    <mat-card-content>
      <div class="date-fields">
        <mat-checkbox class="example-margin" formControlName="isSown">Sown?</mat-checkbox>
        <!-- Date Field -->
        <mat-form-field appearance="outline">
          <mat-label>Choose a date</mat-label>
          <input formControlName="sownDate" matInput [matDatepicker]="picker">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="date-fields">
        <mat-checkbox class="example-margin" formControlName="isInvoiced">Invoiced?</mat-checkbox>
        <mat-form-field appearance="outline">
            <mat-label>Choose a date</mat-label>
            <input formControlName="invoicedDate" matInput [matDatepicker]="isInvoicedPicker">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="isInvoicedPicker"></mat-datepicker-toggle>
            <mat-datepicker #isInvoicedPicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="date-fields">
            <mat-checkbox class="example-margin" formControlName="isPaid">Paid?</mat-checkbox>
            <mat-form-field appearance="outline">
                <mat-label>Choose a date</mat-label>
                <input formControlName="paidDate" matInput [matDatepicker]="isPaidPicker">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="isPaidPicker"></mat-datepicker-toggle>
                <mat-datepicker #isPaidPicker></mat-datepicker>
              </mat-form-field>
        </div>
      <!-- other fields... -->
    </mat-card-content>
  </mat-card>

  <mat-card >
    <mat-card-header>
      <mat-card-title> Breaches </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div *ngIf="breaches?.length > 0; else noBreaches">
        <table mat-table [dataSource]="breaches">
          <!-- Column for grower name -->
          <ng-container matColumnDef="growerName">
            <th mat-header-cell *matHeaderCellDef>Grower Name</th>
            <td mat-cell *matCellDef="let element">{{element.grower.name}}</td>
          </ng-container>

          <!-- Column for business name -->
          <ng-container matColumnDef="businessName">
            <th mat-header-cell *matHeaderCellDef>Business Name</th>
            <td mat-cell *matCellDef="let element">{{element.grower.businessName}}</td>
          </ng-container>

          <!-- Column for breach count -->
          <ng-container matColumnDef="breachCount">
            <th mat-header-cell *matHeaderCellDef>Breach Count</th>
            <td mat-cell *matCellDef="let element">{{element.breachCount}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <ng-template #noBreaches>
        No Breaches
      </ng-template>

    </mat-card-content>
</mat-card>
</form>

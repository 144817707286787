import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomValidators } from 'src/app/_helpers/customValidators';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: UntypedFormGroup;
  error: string = null;
  requestSent = false;
  version:string;

  constructor(private authService: AuthenticationService, private router:Router) {
    this.version = environment.version;
  }

  ngOnInit(): void {
    this.forgotPasswordForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [CustomValidators.required,CustomValidators.email]),
    });

    this.forgotPasswordForm.valueChanges.subscribe((changes) => {
      this.error = null;
    });
  }

  onSubmit(): void {
    let email = this.forgotPasswordForm.get('email').value as string;
    this.authService.forgotPassword(email.toLowerCase()).subscribe({
      error: error => this.error = error,
      next: value =>  {this.requestSent = true;}
    })
  }


}

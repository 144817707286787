import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject, filter, shareReplay } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  private readonly testTermsKey = 'testTermsAccepted';

  private readonly testTermsAccepted:BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor() {

   this.initLocalStorage();

    if(!environment.production) {
      const testTermsAccepted = (localStorage.getItem(this.testTermsKey) === 'true');

      this.testTermsAccepted.next(testTermsAccepted);
    }
  }

  private initLocalStorage()
  {
    if(localStorage.getItem(this.testTermsKey) == null)
    {
      localStorage.setItem(this.testTermsKey, 'false');
    }
  }

  getTestTermsAccepted()
  {
    return this.testTermsAccepted.asObservable().pipe(filter(val => val != null));
  }

  setIsTestTermsAccepted(hideEnvironmentWarning = false)
  {
    if(hideEnvironmentWarning)
    {
      localStorage.setItem(this.testTermsKey,'true');
    }

    this.testTermsAccepted.next(true);
  }

}

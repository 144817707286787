<h2 mat-dialog-title>Create New Grower</h2>
<mat-dialog-content class="mat-typography">
  <form
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
>
<section class="grid grid--row gap--md">
  <mat-form-field appearance="outline">
    <mat-label>Name</mat-label>
    <input formControlName="name" matInput placeholder="">
    <mat-error *ngIf="form.get('name').errors?.['required']">This field is required!</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
      <mat-label>business Name</mat-label>
      <input formControlName="businessName" matInput placeholder="">
      <mat-error *ngIf="form.get('businessName').errors?.['required']">This field is required!</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Contact Number</mat-label>
      <input formControlName="contactNumber" matInput placeholder="">
      <mat-error *ngIf="form.get('contactNumber').errors?.['required']">This field is required!</mat-error>
    </mat-form-field>


    <mat-form-field appearance="outline">
      <mat-label>Contact Email</mat-label>
      <input formControlName="contactEmail" matInput placeholder="">
      <mat-error *ngIf="form.get('contactEmail').errors?.['required']">This field is required!</mat-error>
    </mat-form-field>

    <mat-form-field hasRole [showIfHasRole]="['ADMINISTRATORS']" appearance="outline">
      <mat-label>Company</mat-label>
      <mat-select formControlName="seedCompanyID">
        <option [value]="null" disabled>Choose company</option>
        <mat-option *ngFor="let company of seedCompanyService.getState ()|async" [value]="company.seedCompanyID">
          {{company.companyName}}
        </mat-option>
      </mat-select>
    </mat-form-field>

</section>

</form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
    <button type="button" class="button button--black-outline" [mat-dialog-close]="false">
      Cancel
    </button>
    <button
      type="button"
      class="button button--blue"
      [disabled]="!form.valid"
      (click)="onSubmit()"
      type="submit"
    >
      Save
    </button>
  </footer>
</mat-dialog-actions>

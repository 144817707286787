import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject, takeUntil } from 'rxjs';
import { DrawType } from 'src/app/_helpers/enums';


export type MeasureSettingsChanged  = {
  measureType:DrawType.Circle | DrawType.LineString | DrawType.Polygon;
  measureColour?: string;
}

@Component({
  selector: 'app-measure-settings',
  templateUrl: './measure-settings.component.html',
  styleUrls: ['./measure-settings.component.css']
})
export class MeasureSettingsComponent implements AfterViewInit, OnDestroy {

  private isDragging = false;
  private startX: number;
  private startY: number;
  private currentX: number;
  private currentY: number;
  form: FormGroup;
  colorCtr: AbstractControl = new FormControl(null);

  public selection: DrawType = DrawType.LineString;

  private destroyed$ = new Subject();

  @Output() selectionChanged = new EventEmitter<MeasureSettingsChanged>();

  constructor(private el: ElementRef, private renderer: Renderer2) {


    this.form = new FormGroup({
      'measureColor': new FormControl(),
      'measureType': new FormControl('LineString')
    });

    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(data => {
      this.selectionChanged.emit({measureType: DrawType[this.form.get('measureType').value]})
    })



  }

  ngAfterViewInit()
  {
    this.selectionChanged.emit({measureType: DrawType[this.form.get('measureType').value]})
  }

  ngOnDestroy(): void {
      this.destroyed$.next(null);
  }

  @HostListener('mousedown')
  onMouseDown(event: MouseEvent) {

    if(event === undefined)
    {
return;

    }

    this.isDragging = true;
    this.startX = event.clientX  - this.el.nativeElement.offsetLeft;
    this.startY = event.clientY - this.el.nativeElement.offsetTop;


  }
  @HostListener('mouseup')
  onMouseUp(event: MouseEvent) {

    this.isDragging = false;
  }
  @HostListener('mousemove')
  onMouseMove(event: MouseEvent) {

    if(event === undefined)
    {
      return;
    }
    if (this.isDragging ) {

      this.currentX = event.clientX - this.startX;
      this.currentY = event.clientY - this.startY;
      this.renderer.setStyle(this.el.nativeElement, 'top', `${this.currentY}px`);
      this.renderer.setStyle(this.el.nativeElement, 'left', `${this.currentX}px`);
    }
  }

  @HostListener('document:mouseup')
  onMouseLeave() {
    this.isDragging = false;
  }

  submit() {
    this.selectionChanged.emit({measureType: DrawType[this.form.get('measureType').value]})
  }
}

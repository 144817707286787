import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MapComponent } from './map.component';




import { LayerComponent } from './layers/layer-controls/layer/layer.component';
import { BaseFloatComponent } from './layers/layer-controls/base-float/base-float.component';
import { BaseGroupComponent } from './layers/layer-controls/base-group/base-group.component';
import { LayersComponent } from './layers/layers.component';
import { CreateIsolationDialogComponent } from './isolations/dialogs/create-isolation-dialog/create-isolation-dialog.component';
import { IsolationsComponent } from './isolations/isolations.component';
import { CreateIsolationConfirmationComponent } from './isolations/dialogs/create-isolation-confirmation/create-isolation-confirmation.component';
import { CreatePaddockComponent } from './isolations/dialogs/create-paddock/create-paddock.component';





import { BreachInfoComponent } from './isolations/dialogs/breach-info/breach-info.component';
import { HasRoleDirective } from '../shared/directives/has-role.directive';
import { DisplayDatePipe } from '../shared/pipes/display-date.pipe';
import { SpaceBeforeCapPipe } from '../shared/pipes/space_before_capital.pipe';
import { SharedModule } from '../shared/shared.module';
import { MapRoutingModule } from './map-routing.module';
import { IsolationDetailComponent } from './isolations/components/isolation-detail/isolation-detail.component';
import { HeaderComponent } from './sidebar/header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ZoomComponent } from './components/tools/zoom/zoom.component';
import { EditPaddockComponent } from './isolations/dialogs/edit-paddock/edit-paddock.component';
import { IsolationFormComponent } from './isolations/components/isolation-detail/isolation-form/isolation-form.component';
import { IsolationToolboxOverlayComponent } from './isolations/components/isolation-toolbox-overlay/isolation-toolbox-overlay.component';
import { BreachListComponent } from './isolations/components/isolation-detail/breach-list/breach-list.component';
import { SowPaddockComponent } from './isolations/dialogs/sow-paddock-dialog/sow-paddock.component';
import { DeleteConfirmationDialogComponent } from './isolations/dialogs/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { CropFailedDialogComponent } from './isolations/dialogs/crop-failed-dialog/crop-failed-dialog.component';
import { GeocodeComponent } from './components/tools/geocode/geocode.component';
import { GpsComponent } from './components/tools/gps/gps.component';
import { MeasureComponent } from './components/tools/measure/measure.component';
import { MeasureSettingsComponent } from './components/tools/measure/measure-settings/measure-settings.component';
import { ClearLayerComponent } from './components/tools/clear-layer/clear-layer.component';
import { BasemapsSelectComponent } from './components/tools/basemaps-select/basemaps-select.component';
import { IsolationsVirtualListComponent } from './isolations/components/isolations-virtual-list/isolations-virtual-list.component';
import { ResolveBreachComponent } from './isolations/dialogs/resolve-breach/resolve-breach.component';
import { ResolvedBreachListComponent } from './isolations/components/isolation-detail/resolved-breach-list/resolved-breach-list.component';
import { ResolvedBreachesInfoComponent } from './isolations/dialogs/resolved-breaches-info/resolved-breaches-info.component';
import { ZoomAreaComponent } from './components/tools/zoom-area/zoom-area.component';
import { UpdateIsolationComponent } from './isolations/dialogs/update-isolation/update-isolation.component';
import { LayerGroupComponent } from './layers/layer-controls/layer-group/layer-group.component';
import { AttributeOverlayComponent } from './components/tools/attribute-overlay/attribute-overlay.component';


@NgModule({
    declarations: [
        MapComponent,
        IsolationsComponent,
        LayersComponent,
        LayerGroupComponent,
        LayerComponent,
        BaseFloatComponent,
        BaseGroupComponent,
        SpaceBeforeCapPipe,
        CreateIsolationDialogComponent,
        CreateIsolationConfirmationComponent,
        CreatePaddockComponent,
        SowPaddockComponent,
        EditPaddockComponent,
        BreachInfoComponent,
        IsolationDetailComponent,
        HeaderComponent,
        SidebarComponent,
        ZoomComponent,
        IsolationFormComponent,
        IsolationToolboxOverlayComponent,
        BreachListComponent,
        DeleteConfirmationDialogComponent,
        CropFailedDialogComponent,
        GeocodeComponent,GpsComponent,
        MeasureComponent,
        MeasureSettingsComponent,
        ClearLayerComponent,
        BasemapsSelectComponent,
        IsolationsVirtualListComponent,
        ResolveBreachComponent,
        ResolvedBreachListComponent,
        ResolvedBreachesInfoComponent,
        ZoomAreaComponent,
        UpdateIsolationComponent,
        AttributeOverlayComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        MapRoutingModule,
    ]
})
export class MapModule { }

import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

const JWT_KEY = `sit-jwt-token`;
const REFRESH_TOKEN = `sit-refresh-token`;


@Injectable({
  providedIn: 'root'
})
export class TokenService {
  userRoles: string[];

  jwtHelper: JwtHelperService;

  constructor() {

    this.jwtHelper = new JwtHelperService();

  }

  saveAccessToken(token: string)
  {
    localStorage.setItem(JWT_KEY,token);
  }

  getAccessToken()
  {
    return localStorage.getItem(JWT_KEY);
  }

  clearAccessToken()
  {
    this.userRoles = [];
    return localStorage.removeItem(JWT_KEY);
  }

  saveRefreshToken(token: string)
  {
    localStorage.setItem(REFRESH_TOKEN,token);
  }

  getRefreshToken()
  {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  clearRefreshToken()
  {
    return localStorage.removeItem(REFRESH_TOKEN);
  }

  getTokenExpiryDate()
  {
    const token = this.getAccessToken();

    const decoded_token = this.jwtHelper.decodeToken(token);

    const expiryTimestamp = decoded_token.exp * 1000;


    return new Date(expiryTimestamp);
  }



  parseToken() {
    const token = this.getAccessToken();
    if (token) {
      const decodedToken = this.jwtHelper.decodeToken(token);
      this.userRoles = decodedToken.role instanceof Array ? decodedToken.role : typeof decodedToken.role  === 'string' ? [decodedToken.role] : [];
    }
  }

  hasRole(role: string): boolean {
    if (!this.userRoles || this.userRoles.length == 0) {
      this.parseToken();
    }
    return this.userRoles.includes(role);
  }

  hasAnyGivenRoles(givenRoles: Array<string>) {
    if (!this.userRoles || this.userRoles.length == 0) {
      this.parseToken();
    }
    return givenRoles.some(role => this.userRoles.includes(role));
  }

   isAdmin() : boolean {
    if (!this.userRoles || this.userRoles.length == 0) {
        this.parseToken();
      }
    if(this.userRoles?.includes("ADMINISTRATORS"))
    return true

    return false
  }

  isTokenExpired() {
    const token = this.getAccessToken();
    return token ? this.jwtHelper.isTokenExpired(token) : true;
  }

}

<section class="form-container">
  <header>
      <h1>Forgot Password</h1>
  </header>
  <main>
    <form *ngIf="!requestSent" [formGroup]="forgotPasswordForm"  (ngSubmit)="onSubmit()" class="forgot-password-form">
      <mat-form-field color="red" appearance="outline">
        <mat-label>Email</mat-label>
        <input formControlName="email" matInput placeholder="example@example.com.au">
      </mat-form-field>
    <div class="actions">
      <button type="submit" [disabled]="!forgotPasswordForm.valid" class="button button--brand button--full_width">Reset Password</button>
    </div>

    </form>

    <div *ngIf="requestSent">An email has been sent to you with instructions on how to reset your password</div>
  </main>
  <ng-container *ngIf="version">
    <span class="version_information">{{version}}</span>
  </ng-container>
</section>

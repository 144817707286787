import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import Geolocation from 'ol/Geolocation';
import { Feature, Map } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Style, Fill, Stroke } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { Point } from 'ol/geom';
import { Control } from 'ol/control';

@Component({
  selector: 'map-gps',
  templateUrl: './gps.component.html',
  styleUrls: ['./gps.component.css'],
})
export class GpsComponent implements OnInit, AfterViewInit,OnDestroy {
  @Input() map: Map;

  active: boolean = false;
  geoLocation: Geolocation;
  vectorLayer: VectorLayer<any>;
  control: Control;

  constructor(private elRef:ElementRef) {}

  ngOnInit(): void {
    this.geoLocation = new Geolocation({
      projection: this.map.getView().getProjection(),
      trackingOptions: {
        enableHighAccuracy: true,
        maximumAge: 1000,
      },
    });

    //	this.geoLocation.on('change', geoLoccationUpdate(event));

    //this.geoLocation.on('error', geoLoccationError());

    const accuracyFeature = new Feature();

    this.geoLocation.on('change:accuracyGeometry', () => {
      accuracyFeature.setGeometry(this.geoLocation.getAccuracyGeometry());
    });

    const positionFeature = new Feature();
    positionFeature.setStyle(
      new Style({
        image: new CircleStyle({
          radius: 6,
          fill: new Fill({
            color: '#3399CC',
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 2,
          }),
        }),
      })
    );

    this.geoLocation.on('change:position', () => {
      const coordinates = this.geoLocation.getPosition();
      positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
    });

    this.vectorLayer = new VectorLayer({
      map: this.map,
      source: new VectorSource({
        features: [accuracyFeature, positionFeature],
      }),
    });
  }


  ngAfterViewInit(): void {
    this.control = new Control({target: this.elRef.nativeElement.parentNode, element: this.elRef.nativeElement});
    this.map.addControl(this.control);
  }

  ngOnDestroy(): void {
    if(this.control == null)
    return;

    this.map.removeControl(this.control);
    this.deSelect();
  }

  toggleGps = () => {
    if (this.active) {
      this.deSelect();
    } else {
      this.active = !this.active;
      this.vectorLayer.setVisible(this.active);
      this.geoLocation.setTracking(this.active);

      this.map.render();
    }
  };

  deSelect() {
    if (!this.active) return;

    this.vectorLayer.setVisible(false);
    this.geoLocation.setTracking(false);
    this.active = false;
    this.map.render();
  }

  zoomTo() {
    if (!this.active) {
      return;
    }
    var gpsFeatures = this.vectorLayer.getSource().getFeatures();
    var gpsExtent = this.changeZoomExtent(
      gpsFeatures[1].getGeometry().getExtent()
    );
    this.map.getView().fit(gpsExtent);
  }

  changeZoomExtent(extent) {
    var leftExtent = extent[0];
    var rightExtent = extent[2];
    var bottomExtent = extent[1];
    var topExtent = extent[3];

    var horizontalDiff = rightExtent - leftExtent;
    var horizontalAdjustmentNeeded = (40 - horizontalDiff) / 2;

    var verticalDiff = topExtent - bottomExtent;
    var verticalAdjustmentNeeded = (40 - verticalDiff) / 2;

    var adjustedLeftExtent = leftExtent - horizontalAdjustmentNeeded;
    var adjustedRightExtent = rightExtent + horizontalAdjustmentNeeded;
    var adjustedTopExtent = topExtent + verticalAdjustmentNeeded;
    var adjustedBottomExtent = bottomExtent - verticalAdjustmentNeeded;

    return [
      adjustedLeftExtent,
      adjustedBottomExtent,
      adjustedRightExtent,
      adjustedTopExtent,
    ];
  }
}

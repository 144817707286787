import { Component, Inject, OnDestroy, inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomValidators } from 'src/app/_helpers/customValidators';
import { CropTypesService } from 'src/app/shared/services/crop-types/crop-types.service';
import { UpdateIsolationDTO } from '../../models/updateIsolation.model';
import { CreateIsolationDialogComponent } from '../create-isolation-dialog/create-isolation-dialog.component';
import { GrowersService } from 'src/app/shared/services/growers/growers.service';
import { IsolationService } from '../../isolation.service';
import { tap } from 'rxjs';
import { TokenService } from 'src/app/shared/services/authentication/token.service';

@Component({
  selector: 'app-update-isolation',
  templateUrl: './update-isolation.component.html',
  styleUrls: ['./update-isolation.component.css']
})
export class UpdateIsolationComponent implements OnDestroy {



  public readonly cropTypeService: CropTypesService = inject(CropTypesService);
  public readonly growerService: GrowersService = inject(GrowersService);
  public readonly tokenService: TokenService = inject(TokenService);
  public readonly isolationsService: IsolationService = inject(IsolationService);

  private readonly dialogRef: MatDialogRef<CreateIsolationDialogComponent> = inject(MatDialogRef<CreateIsolationDialogComponent>);

  cropTypes$;
  growers$;
  isolation$;

  form: UntypedFormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) private data:{id}) {

    this.form = new UntypedFormGroup({
      'name': new UntypedFormControl(null, CustomValidators.required),
      'cropTypeID': new UntypedFormControl(null, CustomValidators.required),
      'growerID': new UntypedFormControl(null),
  });

    this.cropTypes$ = this.cropTypeService.getAll();
    this.growers$ = this.growerService.getAll();



    this.isolation$ = this.isolationsService.get(data.id).pipe(tap(
      data => {
        this.form.get('name').patchValue(data.name)
        this.form.get('cropTypeID').patchValue(data.cropTypeID);
        this.form.get('growerID').patchValue(data.growerID);
      }
    ));





  }

  ngOnDestroy(): void {

  }


  onSubmit(){
    const model = new UpdateIsolationDTO();
    const changedValues = Object.keys(this.form.controls)
      .filter(key => this.form.controls[key].dirty);

    for (let key of changedValues) {
      model[key] = this.form.controls[key].value;
    }


    this.dialogRef.close(model);

  }

}


import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { map, Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { TokenService } from 'src/app/shared/services/authentication/token.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  private readonly router: Router = inject(Router);
  private readonly authService: AuthenticationService = inject(AuthenticationService);
  protected readonly breakpointObserver: BreakpointObserver = inject(BreakpointObserver);
  protected readonly tokenService: TokenService = inject(TokenService);

  isProduction = environment.production;
  isSmallScreen$: Observable<boolean>;

  constructor() { }

  ngOnInit(): void {

    this.isSmallScreen$ = this.breakpointObserver
    .observe([Breakpoints.Small, Breakpoints.XSmall])
    .pipe(map((state: BreakpointState) => state.matches));
  }

  logout()
  {
    this.router.navigate(["login"]);
    this.authService.logout();
  }


}

import { Injectable } from '@angular/core';
import { NavigationEnd, Router, UrlTree } from '@angular/router';
import { BehaviorSubject, filter } from 'rxjs';

type HeaderRoute = {
  route: string;
  title: string;
  children?: ChildRoute[];
};

type ChildRoute = {
  route: string;
  title: string;
  backButton?: boolean;
  backRoute?: string;
};


@Injectable({
  providedIn: 'root'
})
export class HeaderService {


  private headerDetails$ = new BehaviorSubject(null);

   routes: HeaderRoute[] = [{
    route: 'user-profile',
    title: 'Settings',
    children: [
      {
        route: 'notifications',
        title: 'Notifications',
        backButton: true,
        backRoute:'user-profile'
      },
      {
        route: 'password-reset',
        title: 'Change Password',
        backButton: true,
        backRoute:'user-profile'
      },
      {
        route: 'preferences',
        title: 'Preferences',
        backButton: true,
        backRoute:'user-profile'
      }
      ]
  }, {
    route:'layers',
    title: 'Layers',
  },
  {
    route:'isolations',
    title: 'Isolation Pins',
    children: [{route: ':id', title: 'Isolation Details', backButton: true, backRoute:'isolations'}]
  }];


  constructor(private router: Router) {

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const matchingRouteObject = this.isOnRouteOrChild(this.routes);
if (matchingRouteObject) {
this.headerDetails$.next(matchingRouteObject);
} else {
  console.log('No matching route object found');
}
    });

  }

  getHeaderDetails()
  {
    return this.headerDetails$.asObservable();
  }

  isOnRouteOrChild(routes: any[]): any {
    // Extract the current route
    const currentUrlTree: UrlTree = this.router.parseUrl(this.router.url);
    const currentRoute: string[] = currentUrlTree.root.children['primary'].segments.map(segment => segment.path);

    for (let route of routes) {
      // Check the main route
      if (currentRoute.includes(route.route)) {
        // Special handling for dynamic children (e.g. ':id')
        if (route.children) {
              const dynamicChildRoute = route.children.find(childRoute => childRoute.route.startsWith(':'));
              if (dynamicChildRoute && currentRoute.length > currentRoute.indexOf(route.route) + 1 && currentRoute[currentRoute.length - 1] !== '') {
                dynamicChildRoute.param = currentRoute[currentRoute.length - 1];
                return dynamicChildRoute;
              }

              const childRoute = route.children.find(childRoute => !childRoute.route.startsWith(':') && currentRoute.includes(childRoute.route));
        if (childRoute) {
          return childRoute;
        }
        }
        return route;
      }
    }

    // Return null if no match is found
    return null;
  }

  navigateToParent(parentRoute) {
    if (parentRoute !== null) {
      this.router.navigate(['/map/', parentRoute]);
    }
  }


}



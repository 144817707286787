<div class="header">
  <div class="header-navigation">
    <ul>
      <li appTooltip [appTooltip]="pair.value.title"  tooltipPosition="left" class="nav-item" *ngFor="let pair of pages | keyvalue" [ngClass]="{'sidebar-page_active':pair.value.active}"  [ngStyle]="pair.value.position == 'end' ? {'margin-left': 'auto'} : {}" (click)="_openPage.emit(pair.value);" ><i [class]="pair.value.className"></i></li>
    </ul>
  </div>
  <div *ngIf="headerService.getHeaderDetails() | async as vm" class="header-actions">
    <div style="place-self: center start;" class="back-button-container">
      <a appTooltip="Back"  tooltipPosition="left" *ngIf="vm.backButton" class="flex-center header_action" (click)="goBack(vm.backRoute)"><i class="fa fa-arrow-left"></i></a>
    </div>
    <h3  style="margin: 0; place-self:center center; text-align:center">{{vm.title}}</h3>
    <button appTooltip="Collapse Sidebar"  tooltipPosition="left"  class="flex-center header_action" style="place-self: center end;" (click)="close()"><i class="fa fa-angle-double-right"></i></button>
  </div>
</div>

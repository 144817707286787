import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Feature } from 'ol';
import { map, tap, switchMap, combineLatest, Observable, of, Subject, startWith, Subscription, EMPTY, filter } from 'rxjs';
import { CovertWKTFeature, ConvertFeatureToWKT } from 'src/app/_helpers/transformations';
import { UsersService } from 'src/app/shared/services/users/users.service';
import { CreatePaddockComponent } from 'src/app/map/isolations/dialogs/create-paddock/create-paddock.component';
import { TokenService } from 'src/app/shared/services/authentication/token.service';
import { GrowersService } from 'src/app/shared/services/growers/growers.service';
import { SeasonsService } from 'src/app/shared/services/seasons/seasons.service';
import { IsolationService } from '../../isolation.service';
import { IsolationDTO } from '../../models/isolation.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MapService } from 'src/app/map/map.service';
import { HeaderService } from 'src/app/map/sidebar/header/header.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { EditPaddockComponent } from '../../dialogs/edit-paddock/edit-paddock.component';
import { BreachInfoComponent, BreachInfoDialogCloseReason } from '../../dialogs/breach-info/breach-info.component';
import { IsolationStatus } from 'src/app/shared/directives/required-status.directive';
import { SowPaddockComponent } from '../../dialogs/sow-paddock-dialog/sow-paddock.component';
import { DeleteConfirmationDialogComponent } from '../../dialogs/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { CropFailedDialogComponent } from '../../dialogs/crop-failed-dialog/crop-failed-dialog.component';
import { ArchiveIsolationDTO } from '../../models/archiveIsolation.model';
import { RelationalDiagramComponentComponent } from 'src/app/shared/components/dialogs/relational-diagram-component/relational-diagram-component.component';
import { CropTypesService } from 'src/app/shared/services/crop-types/crop-types.service';
import { AuditEntryComponent } from 'src/app/shared/components/dialogs/audit-entry/audit-entry.component';
import { ResolveBreachComponent } from '../../dialogs/resolve-breach/resolve-breach.component';
import { ResolvedBreachesInfoComponent } from '../../dialogs/resolved-breaches-info/resolved-breaches-info.component';
import { ErrorComponent } from 'src/app/shared/components/dialogs/error/error.component';
import { SidebarService } from 'src/app/map/sidebar/sidebar.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { UpdateIsolationComponent } from '../../dialogs/update-isolation/update-isolation.component';
import { UpdateIsolationDTO } from '../../models/updateIsolation.model';
@Component({
  selector: 'app-isolation-detail',
  templateUrl: './isolation-detail.component.html',
  styleUrls: ['./isolation-detail.component.css']
})
export class IsolationDetailComponent implements AfterViewInit, OnDestroy {
  protected readonly breakpointObserver: BreakpointObserver = inject(BreakpointObserver);
  private readonly modalService: ModalService = inject(ModalService);


  isolationID;
  isolationForm: FormGroup;

  isolationStatus = IsolationStatus;
  hasPaddock: boolean = false;

  isolationData$;
  breachData$;
  resolvedBreachData$;
  hasPaddock$;

  ZoomToPaddock = new Subject<{id:string, onLoad:boolean}>();
  isSmallScreen$: Observable<boolean>;

  subscription:Subscription;

    constructor(
     private isolationService:IsolationService,
     public tokenService:TokenService,
     public growerService:GrowersService,
     public seasonService:SeasonsService,
     public cropTypeService:CropTypesService,
     private router: Router,
     private route: ActivatedRoute,
     private dialog: MatDialog,
     private mapService:MapService,
      private sidebarService:SidebarService

     ) {

      this.isSmallScreen$ = this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .pipe(map((state: BreakpointState) => state.matches));

      this.isolationData$ = combineLatest([isolationService.getState(),route.params]).pipe(
        switchMap(([state,params]) => {
          if (params["id"]) {
            this.isolationID = params["id"];
            this.zoomToIsolation(params["id"],true)
            return this.isolationService.get(params["id"]);
          } else {

            return of({});
          }
        })
        );

        this.breachData$ = combineLatest([isolationService.getState(),route.params]).pipe(
          switchMap(([state,params]) => {
            if (params["id"]) {
              return this.isolationService.getBreaches(params["id"]);
            } else {

              return of([]);
            }
          }));

          this.resolvedBreachData$ = combineLatest([isolationService.getState(),route.params]).pipe(
            switchMap(([state,params]) => {
              if (params["id"]) {
                return this.isolationService.getResolvedBreaches(params["id"]);
              } else {

                return of([]);
              }
            }));

    this.subscription = combineLatest([this.isSmallScreen$, this.ZoomToPaddock]).subscribe(([isSmallScreen, zoomRequest]) => {
      let feature = this.isolationService.featureMap.get(zoomRequest.id)

      if(isSmallScreen)
      {
        this.mapService.zoomToFeature(feature,[0,0,0,0])

        if(!zoomRequest.onLoad)
        this.sidebarService.close();
      }
      else
      {
        this.mapService.zoomToFeature(feature)
      }

    });
  }

  zoomToIsolation(id:string, onLoad: boolean)
  {
    this.ZoomToPaddock.next({id:id, onLoad:onLoad});

  }

    editPaddock()
    {

        this.dialog.open(EditPaddockComponent, {panelClass:['myDialogClass','map-container'],data:{feature:this.isolationService.paddockMap.get(this.isolationID)}}).afterClosed().subscribe(result => {

          if(result instanceof Feature)
          {
            this.isolationService.updatePaddock(this.isolationID,ConvertFeatureToWKT(result)).subscribe( { next:  response =>
              {
              },
              error: message => this.modalService.showError(message).subscribe()}
            );

          }

      });

    }

    createPaddock()
    {
      this.dialog.open(CreatePaddockComponent, {panelClass:['myDialogClass','map-container'],data:{feature:this.isolationService.featureMap.get(this.isolationID)}}).afterClosed().subscribe(result => {

        if(result instanceof Feature)
        {
          this.isolationService.addPaddock(this.isolationID,ConvertFeatureToWKT(result)).subscribe( { next:  response =>
            {
            },
            error: (message:Error) => this.modalService.showError(message).subscribe()}
          );
        }

      });

    }

    update(id)
    {
      this.dialog.open(UpdateIsolationComponent, {panelClass:[],data:{id:id}}).afterClosed().subscribe(result => {

        if(result instanceof UpdateIsolationDTO)
        {
          this.isolationService.update(id,result).subscribe( { next:  response =>
            {
            },
            error: (message:Error) => this.modalService.showError(message).subscribe()}
          );
        }

      });
    }

    sowPaddock(isolation:IsolationDTO)
    {
      this.dialog.open(SowPaddockComponent, {panelClass:['myDialogClass','map-container'],data:{isolation:isolation}}).afterClosed().subscribe(result => {

        if(result instanceof Feature)
        {
          this.isolationService.addSownPaddockArea(isolation.isolationID,ConvertFeatureToWKT(result)).subscribe();
        }

      });

    }

    viewHistory(id)
    {
      this.dialog.open(AuditEntryComponent, {data:{entityID: id}}).afterClosed().subscribe(result => {

      });
    }

    delete(isolation:IsolationDTO)
    {

      this.dialog.open(DeleteConfirmationDialogComponent).afterClosed().subscribe((result) => {
        if(result && result.comment) {
        this.isolationService.delete(new ArchiveIsolationDTO(isolation.isolationID,result.comment)).subscribe(resp => this.router.navigateByUrl("/map/isolations"));
        }
      })

    }

    test(id)
    {
      this.cropTypeService.get(id).pipe(switchMap(data => {
      return  this.dialog.open(RelationalDiagramComponentComponent, {data: data}).afterClosed()
      })).subscribe();

    }


    markAsFailed(isolation:IsolationDTO)
    {

      this.dialog.open(CropFailedDialogComponent).afterClosed().subscribe((result) => {

        if(result && result.comment) {
          this.isolationService.delete(new ArchiveIsolationDTO(isolation.isolationID,result.comment)).subscribe(resp => this.router.navigateByUrl("/map/isolations"));
        } else {

        }



      })

    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {


  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
      //this.mapService.resetMapView();
  }

  showBreachInfo(breach, isolation: IsolationDTO) {
    this.dialog.open(BreachInfoComponent, { data: breach })
        .afterClosed()
        .pipe(
            switchMap((result: BreachInfoDialogCloseReason) => {
                if (result === BreachInfoDialogCloseReason.DeleteIsolation) {
                    this.delete(isolation);
                    return EMPTY;  // Returns an 'empty' observable
                }
                if (result === BreachInfoDialogCloseReason.ResolveBreaches) {
                    return this.dialog.open(ResolveBreachComponent).afterClosed();
                }
                return EMPTY;
            }),
            filter(data => data !== false),

             switchMap(data => this.isolationService.resolveBreach(isolation.isolationID, breach.breachIDs, data))
        )
        .subscribe();
}

showResolvedBreachInfo(breach, isolation)
{

  this.dialog.open(ResolvedBreachesInfoComponent, { data: breach })
  .afterClosed()

}


}

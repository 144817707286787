import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorComponent } from '../components/dialogs/error/error.component';
import { ConfirmationComponent } from '../components/dialogs/confirmation/confirmation.component';
import { Feature } from 'ol';
import { CreateIsolationDialogComponent } from 'src/app/map/isolations/dialogs/create-isolation-dialog/create-isolation-dialog.component';
import { DeleteConfirmationComponent } from '../components/dialogs/delete-confirmation/delete-confirmation.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private readonly dialog:MatDialog = inject(MatDialog);

  constructor() { }


  showError(error:Error)
  {
    return this.dialog.open(ErrorComponent, {data: { errorMessage:error.message}, maxWidth:'500px'}).afterClosed();
  }

  showConfirmation(displayText: string)
  {
    return this.dialog.open(ConfirmationComponent, {
      data: { displayText: displayText }
    }).afterClosed();
  }

  showBookIsolation(isolationPoint:Feature)
  {
    return this.dialog.open(CreateIsolationDialogComponent,{hasBackdrop:true,panelClass:'myDialogClass', data: {feature: isolationPoint}}).afterClosed();
  }

  showConfirmDelete(entityType: string, entityDetails:string)
  {
    return this.dialog.open(DeleteConfirmationComponent, {
      panelClass:'myDialogClass',
      data: {type: entityType, details:entityDetails}
    }).afterClosed();
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-resolved-breaches-info',
  templateUrl: './resolved-breaches-info.component.html',
  styleUrls: ['./resolved-breaches-info.component.css']
})
export class ResolvedBreachesInfoComponent {


  displayedColumns: string[] = [
    'reason',
    'resolvedDate',
  ];


  constructor(@Inject(MAT_DIALOG_DATA) public data)
  {

  }

}

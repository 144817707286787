import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Style, Fill, Stroke } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { IsolationService } from 'src/app/map/isolations/isolation.service';
import { MapService } from 'src/app/map/map.service';

@Component({
  selector: 'app-isolations-virtual-list',
  templateUrl: './isolations-virtual-list.component.html',
  styleUrls: ['./isolations-virtual-list.component.scss']
})
export class IsolationsVirtualListComponent implements OnDestroy {
  @Input() items: any[];

  private highlightedFeature: any;
  constructor(private isolationService:IsolationService, private mapService: MapService, private router:Router) {

  }

  onHover(isolationID) {
    // Find the corresponding feature on the map
    let feature = this.isolationService.featureMap.get(isolationID)


    const highlightStyle = new Style({
      image: new CircleStyle({
          radius: 10,
          fill: new Fill({
              color: 'rgba(255, 255, 255, 0.8)'
          }),
          stroke: new Stroke({
              color: 'rgba(0, 153, 255, 1)',
              width: 2
          })
      })
  });

      // If found, highlight the feature
      if (feature) {
        feature.setStyle(highlightStyle);
        this.highlightedFeature = feature;

    }


}

onClick(isolationID)
{
  this.router.navigate(['/map/isolations', isolationID]);
}

onMouseOut() {
  if(this.highlightedFeature)
  {
    this.highlightedFeature.setStyle(null);
  }
}

ngOnDestroy(): void {
  if(this.highlightedFeature)
  {
    this.highlightedFeature.setStyle(null);
  }
}

}

import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-resolve-breach',
  templateUrl: './resolve-breach.component.html',
  styleUrls: ['./resolve-breach.component.css']
})
export class ResolveBreachComponent {
  commentControl = new FormControl('', [Validators.required]);


  constructor(private dialogRef:MatDialogRef<ResolveBreachComponent>) {

    this.dialogRef.disableClose = true;
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

 submit()
 {
    this.dialogRef.close(this.commentControl.getRawValue());
 }

}

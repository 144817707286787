import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, switchMap, tap } from "rxjs";
import { CreateGrowerDTO } from "./models/createGrower.model";
import { GrowerDTO } from "./models/grower.model";
import { environment } from "src/environments/environment";
import { UpdateGrowerDTO } from "./models/updateGrower.model";

@Injectable({
  providedIn: 'root',
})
export class GrowersService {

  private growers$ = new BehaviorSubject<Array<GrowerDTO>>([]);

  private readonly endpoint =`${environment.apiUrl}/growers`;

  constructor(private httpClient: HttpClient)
  {
    this.getAll().subscribe();

  }

  getState() {
    return this.growers$.asObservable();
  }

  setState(growers: Array<GrowerDTO>) {

    this.growers$.next(growers);
  }

  get(id)
  {
    return this.httpClient.get<GrowerDTO>(`${this.endpoint}/${id}`);
  }


  getAll()
  {
    return this.httpClient.get<Array<GrowerDTO>>(this.endpoint).pipe(tap(data => this.setState(data)));
  }

  create(grower: CreateGrowerDTO)
  {
    return this.httpClient.post(this.endpoint, grower).pipe(tap(()=> this.getAll().subscribe()));
  }

  update(id:string, model: UpdateGrowerDTO)
  {
    return this.httpClient.put(`${this.endpoint}/${id}` , model).pipe(tap(()=> this.getAll()));
  }

  delete(id: string)
  {
      return this.httpClient.delete(`${this.endpoint}/${id}`).pipe(tap(data => this.getAll()));
  }

}

import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Feature } from 'ol';
import RenderFeature from 'ol/render/Feature';
import { concatMap, filter, from, map, mergeMap, switchMap, tap } from 'rxjs';
import { UsersService } from 'src/app/shared/services/users/users.service';
import { TokenService } from 'src/app/shared/services/authentication/token.service';
import { GrowersService } from 'src/app/shared/services/growers/growers.service';
import { SeasonsService } from 'src/app/shared/services/seasons/seasons.service';

import { ConvertFeatureToWKT, CovertWKTFeature } from 'src/app/_helpers/transformations';
import { CreatePaddockComponent } from '../create-paddock/create-paddock.component';
import { IsolationService } from '../../isolation.service';
import { IsolationDTO } from '../../models/isolation.model';
import { MapService } from 'src/app/map/map.service';
import { EditPaddockComponent } from '../edit-paddock/edit-paddock.component';

export enum BreachInfoDialogCloseReason{
  Close,
  DeleteIsolation,
  ResolveBreaches,
  Unknown
}

@Component({
  selector: 'app-breach-info',
  templateUrl: './breach-info.component.html',
  styleUrls: ['./breach-info.component.css']
})
export class BreachInfoComponent{

  constructor(
    @Inject(MAT_DIALOG_DATA) public breach:any,
    private dialog:MatDialogRef<BreachInfoComponent>
   )
   {

   }

   close()
   {
    this.dialog.close(BreachInfoDialogCloseReason.Close);
   }

   deleteIsolation()
   {
    this.dialog.close(BreachInfoDialogCloseReason.DeleteIsolation);
   }

   resolveBreaches()
   {
    this.dialog.close(BreachInfoDialogCloseReason.ResolveBreaches);
   }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsolationsComponent } from './isolations/isolations.component';
import { LayersComponent } from './layers/layers.component';
import { MapComponent } from './map.component';
import { IsolationDetailComponent } from './isolations/components/isolation-detail/isolation-detail.component';

const routes: Routes = [
  {path: '', component: MapComponent, children: [
    {path:'layers', component:LayersComponent},
    {path:'isolations', component:IsolationsComponent},
    {path:'isolations/:id', component:IsolationDetailComponent},
    {
      path:'user-profile', loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule)
    }
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MapRoutingModule { }

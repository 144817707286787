<h1 mat-dialog-title>Confirm Deletion</h1>
<div mat-dialog-content>
  <p>Are you sure you want to delete this isolation?</p>
  <mat-form-field class="full-width">
    <textarea resize="none" matInput  placeholder="Please enter comment" [formControl]="commentControl" required></textarea>
    <mat-error *ngIf="commentControl.hasError('required')">Comment is required</mat-error>
  </mat-form-field>
</div>
<mat-dialog-actions class="actions" align="end">
  <button  class="button button--muted button--xs" (click)="onCancelClick()">Cancel</button>
  <button  class="button button--danger button--xs" [disabled]="!commentControl.valid" (click)="confirmDeletion()">Yes</button>
</mat-dialog-actions>

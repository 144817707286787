import { Injectable, inject } from '@angular/core';

import * as signalR from '@microsoft/signalr'
import { TokenService } from './authentication/token.service';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from './toast.service';
import { environment } from 'src/environments/environment';
import { NotificationsService } from 'src/app/map/user-profile/notifications/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {

  private itemsSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);

  private readonly notificationService: NotificationsService = inject(NotificationsService);
  private readonly toastService: ToastService = inject(ToastService);

  isConnected:BehaviorSubject<boolean> = new BehaviorSubject(false);

  alerts$ = this.itemsSubject.asObservable();

  private readonly endpoint = environment.hubUrl;

  constructor(private tokenService:TokenService) {

  }
   hubConnection: signalR.HubConnection

   public stopConnection()
   {
    this.hubConnection?.stop();
    this.isConnected.next(false);
   }

    public startConnection(){
      this.hubConnection = new signalR.HubConnectionBuilder()
                              .withUrl(this.endpoint,{accessTokenFactory: () => this.tokenService.getAccessToken()})
                              .withAutomaticReconnect()
                              .build();
      this.hubConnection
        .start()
        .then(() => {
            this.isConnected.next(true);
            window.addEventListener('beforeunload', () => {
            this.hubConnection.stop();
            this.isConnected.next(false);
          });

          this.hubConnection.on('ISOLATION_BREACH', (id: string) => {
            this.notificationService
            .addItem({id: id, type:'BREACH'});
            this.toastService.newNotification();
          });

          this.hubConnection.on('BREACH_RESOLUTION', (id: string) => {
            this.notificationService.addItem({id: id, type:'RESOLUTION'});
            this.toastService.newNotification();
          });
        })
        .catch(err => console.log('Error while starting connection: ' + err))
    }



}

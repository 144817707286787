import { Component, NgZone, OnInit, ViewChild, inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MapService } from '../map.service';
import { SidebarService } from './sidebar.service';
import { MatDrawer } from '@angular/material/sidenav';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Observable, combineLatest, map, tap } from 'rxjs';
import { IsolationService } from '../isolations/isolation.service';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  @ViewChild('sideNav', {static:true}) drawer: MatDrawer;

  protected readonly breakpointObserver: BreakpointObserver = inject(BreakpointObserver);
  private readonly isolationService:IsolationService = inject(IsolationService);

  isSmallScreen$: Observable<boolean>;

  display$;

  constructor(public mapService: MapService,private zone: NgZone, private router:Router, public route:ActivatedRoute, public sidebarService:SidebarService)
  {

    this.isSmallScreen$ = this.breakpointObserver
    .observe([Breakpoints.Small, Breakpoints.XSmall])
    .pipe(map((state: BreakpointState) => state.matches));

   this.display$= combineLatest([this.isolationService.isDrawActive, this.isSmallScreen$])
   .pipe(
     map(([isDrawActive, isSmallScreen]) => (isDrawActive && isSmallScreen) ? 'none' : 'grid')
   );
  }

  ngOnInit(): void {
      this.sidebarService.setDrawer(this.drawer)
  }

  openPage(page)
  {



    if(this.router.url.startsWith( `/map/${page.route}`))
    {
      if(this.drawer.opened)
      {
        this.router.navigate(['map'])
        page.active = false;
      }
      else
      {
        this.drawer.open();
      }

    }

    else
    {
      let urlSegments = this.router.url.split('/');


      let activePage = this.sidebarService.getPages().get(urlSegments[urlSegments.length - 1]);

      if(activePage)
      activePage.active = false;

      this.router.navigate([`/map/${page.route}`])
      page.active = true;
    }


  }


}

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { TokenService } from '../services/authentication/token.service';

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(private tokenService: TokenService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree > | Promise<boolean> | boolean | UrlTree {
    const expectedRoles = route.data['roles'];
    return this.tokenService.hasAnyGivenRoles(expectedRoles) ? true : this.router.createUrlTree([''])
  }
}

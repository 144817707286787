
<div class="header">
  <h2 mat-dialog-title>Draw a Paddock Outline</h2>
  <p>Record the intended sowing area by clicking the paddock corners on the map below.</p>
  <p>To complete the drawing click back on the first corner.</p>
</div>


<mat-dialog-content style="width: 100%; height:100%; position:relative" class="mat-typography">
<div #paddockMap class="map">


</div>
<div
id="esk-toolbar"
class="esk-toolbar"
[@flyInOut]
>
<map-zoom  [map]="map"></map-zoom>
<map-gps  [map]="map" ></map-gps>
<map-clear-layer (Clear)="clear()"></map-clear-layer>
</div>

<map-base-maps-select (SelectedBaseMap)="onBaseMapChange($event)"></map-base-maps-select>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
    <button type="button" class="button button--black-outline" [mat-dialog-close]="false">
      Cancel
    </button>
    <button
      type="button"
      class="button button--blue"
      (click)="onSubmit()"
      [disabled]="hasError"
    >
      Save
    </button>
  </footer>
</mat-dialog-actions>

import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'app-breach-list',
  templateUrl: './breach-list.component.html',
  styleUrls: ['./breach-list.component.css']
})
export class BreachListComponent {

  @Input() breaches: any[] | null;

  @Output() breachClicked = new EventEmitter();

}

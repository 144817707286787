import { Directive, Input, Renderer2, ElementRef, OnChanges, SimpleChanges } from '@angular/core';

export enum IsolationStatus {
  Deleted = -1,
  Created = 1,
  PaddockCreated = 2,
  PaddockSown = 3
}

@Directive({
  selector: '[hasRequiredStatus]',
})
export class IsolationStatusDirective implements OnChanges {
  @Input() status: string | number;
  @Input() requiredStatus: IsolationStatus;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if(changes['status'] || changes['requiredStatus']) {
      this.updateStatus();
    }
  }

  private updateStatus() {
    const _status =  typeof this.status === "string" ? parseInt(this.status) : this.status;

    if(_status != this.requiredStatus)
    {
      this.renderer.setStyle(this.element.nativeElement, 'display', 'none');
    }
    else
    {
      this.renderer.removeStyle(this.element.nativeElement, 'display');
    }
  }
}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSliderChange } from '@angular/material/slider';
import { Layer } from 'ol/layer';
import LayerGroup from 'ol/layer/Group';
import VectorLayer from 'ol/layer/Vector';

@Component({
  selector: 'app-layer-group',
  templateUrl: './layer-group.component.html',
  styleUrls: ['./layer-group.component.scss']
})
export class LayerGroupComponent implements OnInit, OnChanges {

  @Input() layerGroup: LayerGroup;
  @Input() type: 'Base' | 'Normal' | 'Singular';

  layerGroups = [];
  layers = [];

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
      if(changes["layerGroup"].currentValue != undefined)
      {
        this.layerGroup.getLayers().forEach((layer)=>
        {
         if(layer instanceof LayerGroup)
         {
           this.layerGroups.push(layer);
         }
         if(layer instanceof VectorLayer)
         {
           this.layers.push(layer);
         }
        });
      }
  }

  getLayerGroupVisibility(): boolean
  {
    return this.layerGroup?.getVisible();
  }

  setLayerGroupVisibility(event: MatSlideToggleChange) : void
  {
    this.layerGroup?.setVisible(event.checked);
  }

  formatLabel(value: number) {
    return value + '%';
  }

}

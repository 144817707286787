import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Feature, Overlay } from 'ol';
import OlMap from 'ol/Map'

@Component({
  selector: 'app-attribute-overlay',
  templateUrl: './attribute-overlay.component.html',
  styleUrls: ['./attribute-overlay.component.css'],
   host: {'class': 'ol-popup'}
})
export class AttributeOverlayComponent implements OnInit,OnDestroy {

  @Input() selectedFeature: Feature<any>;
  @Input() map: OlMap
  @Input() attributeAliasMap: Map<any,any>;

  attributes = [];

  data: HistoricalIsolationAttributes;

  overlay: Overlay;

  constructor(private elRef:ElementRef) { }

  ngOnInit(): void {

    this.overlay = new Overlay({
      element: this.elRef.nativeElement,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });

    this.setupAttributesArray();

    this.overlay.setPosition(this.selectedFeature.getGeometry().getFirstCoordinate());
    this.map.addOverlay(this.overlay);
  }

  ngOnDestroy(): void {
      this.map.removeOverlay(this.overlay);
  }

  setupAttributesArray()
  {

    let props = this.selectedFeature.getProperties();


    this.data = new HistoricalIsolationAttributes(props['isolationName'],props['growerName'], props['pinnedDate'],props['status'],props['codeName'])

  }

  close(event)
  {
    this.overlay.setPosition(undefined);
    event.target.blur();
  }

}


class HistoricalIsolationAttributes{

  isolationName:string;
  growerName:String;
  pinnedDate:string;
  status:string;
  codeName:string;
  constructor(isolationName, growerName, pinnedDate,status,codeName) {
    this.isolationName = isolationName;
    this.growerName = growerName;
    this.pinnedDate = pinnedDate;
    this.status = status;
    this.codeName = codeName;
  }
}

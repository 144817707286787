import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NotificationToastComponent } from '../toast/notification-toast/notification-toast.component';

@Injectable({
  providedIn: 'root',
})
export class ToastService {

  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(private snackBar: MatSnackBar, private router:Router) {}

  showSuccess(message: string): void {
    this.snackBar.open(message,null,{
      panelClass: ['success-snack-bar'],
      duration:5000
    });
  }

  showError(message: string): void {
    this.snackBar.open(message, null, {
      panelClass: ['error-snack-bar'],
      duration:5000
    });
  }

  newNotification() {
    this.snackBar.openFromComponent(NotificationToastComponent, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['notification-snack-bar'],
      duration:10000
    }).onAction().subscribe( () => this.router.navigateByUrl('/map/user-profile/notifications'));
  }

}

import { Injectable, NgZone, inject } from '@angular/core';
import OlMap from 'ol/Map';
import { ProjectionLike } from 'ol/proj';
import * as olProj from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import View from 'ol/View';
import { mapProjEPSG } from 'src/app/_helpers/projection_setup';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import BaseLayer from 'ol/layer/Base';
import LayerGroup from 'ol/layer/Group';
import { IsolationService } from './isolations/isolation.service';
import {
  ConvertGeoJsonToFeatureCollection,
} from 'src/app/_helpers/transformations';
import { Feature } from 'ol';
import { Style, Fill, Stroke, Text, Circle } from 'ol/style';
import { Attribution, ScaleLine } from 'ol/control';
import { Select } from 'ol/interaction';
import { Router } from '@angular/router';
import { SidebarService } from './sidebar/sidebar.service';
import { pointerMove } from 'ol/events/condition';




import { BasemapsService } from './Basemaps.service';
import { ArchivedIsolationsService } from './archivedIsolations.service';
import { Subject } from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { MeasureService } from './components/tools/measure/measure.service';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private readonly sidebarService: SidebarService = inject(SidebarService);
  private readonly isolationService: IsolationService = inject(IsolationService);
  private readonly basemapsService: BasemapsService = inject(BasemapsService);
  private readonly archivedService: ArchivedIsolationsService = inject(ArchivedIsolationsService);
  private readonly zone: NgZone = inject(NgZone);
  private readonly router: Router = inject(Router);
  private readonly measureService:MeasureService = inject(MeasureService);

  private nonTraditionalCarrotAreaLayer = new VectorLayer({source:new VectorSource(),properties: {title: 'Non-Traditional Carrot Area'}});
  private tasmaniaBoundaryLayer = new VectorLayer({ source: new VectorSource() });
  private map: OlMap;
  private proj: ProjectionLike | null = olProj.get(mapProjEPSG);
  tasmania;

  private select: Select;
  historicalSelect: Select;

  private initialCenter = [467000, 5369000];
  private initialZoom = 6;
  baseMapsLoaded = new BehaviorSubject(false);
  historicalLayersLoaded = new BehaviorSubject(false);

  constructor() {

    this.init();
  }

   init() {

    this.map = new OlMap({
      layers: [
        this.nonTraditionalCarrotAreaLayer,
        this.isolationService.isolationGroup,
      ],
      view: new View({
        center: this.initialCenter,
        projection: this.proj == null ? null : this.proj,
        zoom: this.initialZoom,
        maxZoom: 23,
      }),
      controls: [
        new Attribution(),
        new ScaleLine({
          bar: true,
          minWidth: 150,
        }),
      ],
    });



    this.setupBaseMaps();
    this.setupSelect();
    this.setupHoverInteraction();
    this.setupNonTraditionalCarrotAreaLayer();
    this.setupTasmaniaBoundaryLayer();
    this.archivedService.buildLayers().subscribe(layerGroups => {
      let layers:Array<any> = []
      layerGroups.forEach(group =>  {
        this.map.getLayers().insertAt(1,group)
        layers.push(group.getLayersArray()[0])
      });

      this.historicalSelect = new Select({ layers: layers, hitTolerance:20});
      this.map.addInteraction(this.historicalSelect)
      this.historicalLayersLoaded.next(true);
    })

    this.map.on('rendercomplete', (e) => {
      this.map.updateSize();
    });

  }

  async setupBaseMaps()
  {
    let layers = await this.basemapsService.buildLayers();
    let basemapsGroup = new LayerGroup({properties: {title: 'Background Maps'}, layers:layers});
    this.map.getLayers().insertAt(0,basemapsGroup);
    this.baseMapsLoaded.next(true);
  }

  async setupNonTraditionalCarrotAreaLayer() {

   let features =  await this.loadGeoJsonFile('/assets/geojson/non-traditional_carrot_area_28355.geojson');

    this.nonTraditionalCarrotAreaLayer.getSource().addFeatures(features)

    this.nonTraditionalCarrotAreaLayer.setStyle([
      new Style({
        fill: new Fill({ color: [255, 255, 255, 0.25] }),
        stroke: new Stroke({ color: [34, 139, 230, 1], width: 1 }),
      }),
      new Style({
        text: new Text({
          text: 'Non-Traditional Carrot Area',
          font: '400 18px "Arial"',
          fill: new Fill({
            color: [255, 255, 255, 1.0],
          }),
          // rotation : rotation,
          textBaseline: 'bottom',
          stroke: new Stroke({ color: [0, 0, 0, 1.0], width: 2 }),
        }),

        zIndex: 100,
      }),
    ]);

    this.nonTraditionalCarrotAreaLayer.setVisible(false);
  }


  async setupTasmaniaBoundaryLayer() {
    let features =  await this.loadGeoJsonFile('/assets/geojson/tas_coast_simplified_28355.geojson');
    console.log("Tasmania Features",features);

    this.tasmania = features;
    this.tasmaniaBoundaryLayer.getSource().addFeatures(features)

  }


  loadGeoJsonFile(location: string): Promise<Feature[]> {
    return new Promise<Feature[]>(async (resolve, reject) => {
        try {
            let response = await fetch(location);
            if (!response.ok) {
                reject(`Error fetching data: ${response.statusText}`);
                return;
            }

            let geoJsonData = await response.json();
            const features = ConvertGeoJsonToFeatureCollection(geoJsonData);
            resolve(features);
        } catch (error) {
            reject(error);
        }
    });
}

  setupHoverInteraction()
  {
     const hover =  new Select({
      condition: pointerMove,
      layers: [this.isolationService.layer],
      hitTolerance:10,
      style: (feature) => {
       return new Style({
          image: new Circle({
            radius: 5,
            fill: new Fill({
              color: [255,0,0,0.5],
            }),
            stroke: new Stroke({
              width: 1,
              color: [255,0,0,1],
            }),

          }),
          text: new Text({
            text: feature.get('name'),
            fill: new Fill({color: '#000'}),
            backgroundFill: new Fill({color: 'rgba(255, 255, 255, 0.8)'}),
            padding: [2, 4, 2, 4], // [top, right, bottom, left]
            offsetX: 0,
            offsetY: -10,
            font: '17px Outfit'
          })
        })
      }
    });

    this.map.addInteraction(hover);
  }


  /**
   * When the user clicks on a feature, zoom to the feature's geometry.
   * @param feature - Feature<any>
   * @param padding - top, right, bottom, left padding in pixels
   */
  zoomToFeature(feature: Feature<any>, padding: [number,number,number,number] = [0, 600, 0, 0],) {
    if (feature == null) {
      return;
    }
    let polygon = feature.getGeometry();

    this.map.getView().fit(polygon, {
      duration: 2000,
      maxZoom: 12,
      padding: padding,
    });
  }

  resetMapView() {
    this.map.getView().animate({
      center: this.initialCenter,
      zoom: this.initialZoom,
      duration: 2000, // Duration in milliseconds
    });

    this.deSelect();
  }



  disableSelect() {
    this.map.removeInteraction(this.select);
  }

  enableSelect() {
    this.map.addInteraction(this.select);
  }

  setupSelect() {
    this.select = new Select({ layers: [this.isolationService.layer], hitTolerance:20});

    this.enableSelect();

    this.select.on('select', (event) => {
      if (event.selected.length == 0) return;

      this.sidebarService.open();

      if(this.measureService.getIsActive() == false)
      {
        this.zone.run(() => {
          this.router.navigate([
            '/map/isolations',
            event.selected[0].get('isolationID'),
          ]);
        });
      }

    });
  }

  deSelect() {
    this.select.getFeatures().pop();
  }


  /**
   * This function returns the map object.
   * @returns The map object.
   */
  getMap(): OlMap {
    return this.map;
  }

  setTarget(element) {
    this.map.setTarget(element);
    this.map.updateSize();
  }

  updateSize() {
    this.map.updateSize();
  }

  withinTasmania(coordinate: number[]) {
    return this.tasmania.some((feature) => {
      let polygon = feature.getGeometry();
      return polygon.intersectsCoordinate(coordinate);
    });
  }

  /**
   * It returns the first layer in the map's layer collection whose title property matches the title
   * parameter
   * @param {string} title - The title of the layer you want to get.
   * @returns A TileLayer, VectorLayer, or BaseLayer.
   */
  getLayer(title: string): TileLayer<any> | VectorLayer<any> | BaseLayer {
    return this.map
      .getLayers()
      .getArray()
      .find((lyr) => lyr.get('title') == title);
  }

  getLayerGroup(selector: string, value: string) {
   return this.map
      .getLayers()
      .getArray()
      .filter((layers) => layers instanceof LayerGroup)
      .find((lyr) => lyr.get(selector) == value);


  }


}

export class ArchiveIsolationDTO {

  public isolationID: string;
  public comment: string;

  /**
   *
   */
  constructor(isolationID, comment) {
    this.comment = comment;

    this.isolationID = isolationID;
  }

}

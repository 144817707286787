import { Injectable, inject } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, filter } from 'rxjs';
import { IsolationService } from '../isolations/isolation.service';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  private pages =new Map();

  private drawer: MatDrawer;



  constructor(public route:ActivatedRoute, public router:Router) {
    this.pages.set('layers', {title: 'Layers', route: 'layers', className: 'fa fa-layer-group', active: false});
    this.pages.set('isolations',  {title: 'Isolations', route: 'isolations', className: 'fa fa-map-marked-alt', active: false} );
    this.pages.set('user-profile',  {title: 'Settings', route: 'user-profile', className: 'fa fa-cog', active: false, position:'end'} );

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.deactivateAllPages()
      let url = event.urlAfterRedirects;
      let pageName = url.split('/')[2];  // assuming the page name is the first path segment
      if (this.pages.has(pageName)) {
        this.pages.get(pageName).active = true;
      }
    });





  }


  setDrawer(drawer)
  {
    this.drawer = drawer;
  }

  close()
  {
    this.drawer.close();
  }

  open()
  {
    this.drawer.open();
  }



  getPages()
  {
    return this.pages;
  }

  private deactivateAllPages() {
    this.pages.forEach(page => page.active = false);
  }
}

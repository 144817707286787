import { Component, Inject, inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Feature } from 'ol';
import { CropTypesService } from 'src/app/shared/services/crop-types/crop-types.service';
import { GrowersService } from 'src/app/shared/services/growers/growers.service';
import { SeasonsService } from 'src/app/shared/services/seasons/seasons.service';
import { CustomValidators } from 'src/app/_helpers/customValidators';
import { ConvertFeatureToWKT } from 'src/app/_helpers/transformations';
import { CreateIsolationDTO } from '../../models/createIsolation.model';
import { CreateIsolationConfirmationComponent } from '../create-isolation-confirmation/create-isolation-confirmation.component';
import { CreateGrowerDialogComponent } from 'src/app/shared/services/growers/dialogs/create-grower-dialog/create-grower-dialog.component';
import { TokenService } from 'src/app/shared/services/authentication/token.service';
import { tap } from 'rxjs';

@Component({
  selector: 'app-create-isolation-dialog',
  templateUrl: './create-isolation-dialog.component.html',
  styleUrls: ['./create-isolation-dialog.component.scss']
})
export class CreateIsolationDialogComponent {

  public tokenService:TokenService = inject(TokenService);

  createIsolationForm: UntypedFormGroup;

  isolation: CreateIsolationDTO;

  cropTypes$;

  public seasons$;

  constructor(
    public dialogRef: MatDialogRef<CreateIsolationDialogComponent>,
    public growerService: GrowersService,
    public seasonService:SeasonsService,
    public cropTypeService: CropTypesService,
    @Inject(MAT_DIALOG_DATA) private data:{feature:Feature<any>},
    private dialog: MatDialog
     )
      {
    this.dialogRef.disableClose = true;
        this.cropTypes$ = cropTypeService.getAll();

    this.createIsolationForm = new UntypedFormGroup({
      'growerID': new UntypedFormControl(null,[CustomValidators.required]),
      'seasonID': new UntypedFormControl(null,[CustomValidators.required]),
      'cropTypeID': new UntypedFormControl(null,[CustomValidators.required]),
      'name': new UntypedFormControl(null,[CustomValidators.required]),

    });

    this.seasons$ = seasonService.getState().pipe(
      tap(seasons => {
        if (seasons.length === 1) {
          this.createIsolationForm.get('seasonID').patchValue(seasons[0].seasonID);
        }
      })
    );

  }

  addGrowerClicked()
  {
      this.dialog.open(CreateGrowerDialogComponent).afterClosed().subscribe(result => {

        if(typeof result === 'string')
        {
            this.createIsolationForm.get('growerID').setValue(result);
        }

      })
  }

  onSubmit()
  {

    this.dialog.open(CreateIsolationConfirmationComponent,).afterClosed().subscribe(result =>
    {
        if(!result)
        {
          return;
        }

        let iso = new CreateIsolationDTO
        ({
            growerID: this.createIsolationForm.get('growerID').getRawValue(),  seasonID: this.createIsolationForm.get('seasonID').getRawValue(),
            cropTypeID: this.createIsolationForm.get('cropTypeID').getRawValue(),
            isolationGeom: ConvertFeatureToWKT(this.data.feature), name:this.createIsolationForm.get('name').value
         })

        this.dialogRef.close(iso);
    })


  }
}

<mat-expansion-panel expanded="true">
  <mat-expansion-panel-header >
    <mat-panel-title>
    </mat-panel-title>
    <mat-panel-description>
      {{ layerGroup | attribute:'title' }}
    </mat-panel-description>
  </mat-expansion-panel-header>


<mat-radio-group
(change)="setBaseMapVisible()"
  aria-labelledby="example-radio-group-label"
  class="example-radio-group grid gap-xs"
  [(ngModel)]="selectedBaseMap">
  <mat-radio-button class="example-radio-button" *ngFor="let layer of layers" [value]="layer">
    {{ layer | attribute:'title' }}
  </mat-radio-button>
</mat-radio-group>

</mat-expansion-panel>

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';



import { AdminModule } from './admin/admin.module';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { RoleGuard } from './shared/guards/role.guard';
import { HasRoleDirective } from './shared/directives/has-role.directive';
import { authInterceptorProviders } from './shared/interceptors/auth.interceptor';

import { MapModule } from './map/map.module';
import { SharedModule } from './shared/shared.module';
import { SeasonsService } from './shared/services/seasons/seasons.service';
import { SignalrService } from './shared/services/signalr.service';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { CommonModule } from '@angular/common';
import { BreachRejectedComponent } from './components/Breach-Rejected/breach-rejected.component';
import { OnlineStatusModule } from 'ngx-online-status';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    NotFoundComponent,
    BreachRejectedComponent


  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    AdminModule,
    MapModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    OnlineStatusModule
  ],
  providers: [RoleGuard,SeasonsService, authInterceptorProviders,SignalrService],
  bootstrap: [AppComponent]
})
export class AppModule { }

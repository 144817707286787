<h2 mat-dialog-title>Update Isolation</h2>
<mat-dialog-content class="mat-typography">

  <form
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
  >
<section *ngIf="isolation$ | async" class="grid grid--row gap--md">

  <mat-form-field appearance="outline">
    <mat-label>Name</mat-label>
    <input formControlName="name" matInput placeholder="">
    <mat-error *ngIf="form.get('name').errors?.['required']">This field is required!</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Grower</mat-label>
    <mat-select formControlName="growerID">
      <option [value]="null" disabled>Choose a Grower</option>
      <mat-option *ngIf="tokenService.hasAnyGivenRoles(['ADMINISTRATORS','OPERATIONS'])" (click)="addGrowerClicked()" ><i class="fa fa-plus"></i> Add Grower</mat-option>
      <mat-option *ngFor="let growers of growers$ | async" [value]="growers.growerID">
        {{growers.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Parent Codename</mat-label>
    <mat-select formControlName="cropTypeID">
      <option [value]="null" disabled>Choose a Parent Codename</option>
      <mat-option *ngFor="let cropType of cropTypes$ | async" [value]="cropType.cropTypeID">
        {{cropType.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>


</section>
  </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
    <button type="button" class="button button--black-outline" [mat-dialog-close]="false">
      Cancel
    </button>
    <button
      type="button"
      class="button button--blue"
      [disabled]="!form.valid"
      (click)="onSubmit()"
      type="submit"
    >
      Save
    </button>
  </footer>
</mat-dialog-actions>

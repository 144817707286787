<h2 mat-dialog-title>Confirmation of Payment</h2>
<mat-dialog-content class="mat-typography">
  <p>Upon placing this isolation pin a check will be made against other company’s isolation pins that could end up in an isolation breach. After accepting below, you will be notified within this app and via email if any breaches are identified and if any future isolations breach with this pin.</p>

  <p>Companies who participate in the Seed Isolation Tool program have agreed to paying for usage based on the number of isolation pins that are created. By clicking “Yes”, you acknowledge that creating this isolation pin is a financial event.</p>

  <p>Create this isolation pin?</p>

  <p class="test-message" *ngIf="!isProduction">Test System - Financial events do not apply.</p>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
    <button type="button" class="button button--black-outline" [mat-dialog-close]="false">
      No
    </button>
    <button
      type="button"
      class="button button--blue"
      [mat-dialog-close]="true"
    >
      Yes
    </button>
  </footer>
</mat-dialog-actions>

import { Injectable } from "@angular/core";

import { HttpClient } from '@angular/common/http';
import { CreateIsolationDTO } from "./models/createIsolation.model";
import { BehaviorSubject, Subject, catchError, switchMap, tap, throwError } from "rxjs";
import { IsolationDTO } from "./models/isolation.model";
import VectorLayer from "ol/layer/Vector";
import { Feature, VectorRenderTile } from "ol";
import VectorSource from "ol/source/Vector";
import { CovertWKTFeature } from "src/app/_helpers/transformations";
import { Circle, Fill, Stroke, Style } from "ol/style";


import RenderFeature from "ol/render/Feature";
import { mapProjEPSG } from "src/app/_helpers/projection_setup";
import { SignalrService } from "src/app/shared/services/signalr.service";
import { environment } from "src/environments/environment";
import { sownAreaStyle } from "src/app/_helpers/layer.styles";
import { ArchiveIsolationDTO } from "./models/archiveIsolation.model";
import LayerGroup from "ol/layer/Group";
import { UpdateIsolationDTO } from "./models/updateIsolation.model";


@Injectable({
  providedIn: 'root',
})
export class IsolationService {


  private readonly endpoint = environment.apiUrl + "/isolations"
  private styleCache = new Map<string,Style>();
  public isolations$ = new BehaviorSubject<Array<IsolationDTO>>([]);
  public featureMap = new Map();
  public paddockMap = new Map();
  public isDrawActive = new BehaviorSubject(false);
  private vectorSource = new VectorSource();
  public readonly layer = new VectorLayer({source: this.vectorSource, style: (feature, resolution) => {return this.isolationPointStyle(feature,resolution,'')}});
  private readonly paddockLayer = new VectorLayer({source: new VectorSource()});
  private readonly sownAreaLayer = new VectorLayer({source: new VectorSource(), style:sownAreaStyle});

  public isolationGroup = new LayerGroup({layers:[this.paddockLayer,this.sownAreaLayer,this.layer]});


    constructor(private httpClient: HttpClient,private signalRService: SignalrService)
    {

      this.isolations$.pipe(tap(data => {

        this.vectorSource.clear();
        this.featureMap.clear();
        this.paddockMap.clear();
        this.paddockLayer.getSource().clear();
        this.sownAreaLayer.getSource().clear();

        data.forEach(isolation => {
          var isolationFeature = CovertWKTFeature(isolation.isolationGeom);
          isolationFeature.setProperties(isolation);
          this.vectorSource.addFeature(isolationFeature);
          this.featureMap.set(isolation.isolationID, isolationFeature);

          if(isolation.paddockGeom != null)
          {
            var paddockFeature = CovertWKTFeature(isolation.paddockGeom);

            this.paddockLayer.getSource().addFeature(paddockFeature);
            this.paddockMap.set(isolation.isolationID, paddockFeature);
          }

          if(isolation.sownGeom != null)
          {
            var feature = CovertWKTFeature(isolation.sownGeom);
            this.sownAreaLayer.getSource().addFeature(feature);
          }
        });
      })).subscribe();
    }

    setSignalRHook()
    {
      if(this.signalRService.hubConnection == null)
        return;

      this.signalRService.hubConnection.on('refresh_isolations', () => {
        this.getAll().subscribe();
      });
    }

     unsetSignalRHook()
    {
      if(this.signalRService.hubConnection == null)
      return;

      this.signalRService.hubConnection.off('refresh_isolations');
    }


    OnNavigateAwayFromMap()
    {
      this.isolations$.next([]);
      this.unsetSignalRHook();
    }

    getState()
    {
        return this.isolations$.asObservable()
    }

    setState(growers: Array<IsolationDTO>) {

      this.isolations$.next(growers);
    }

    get(id: string)
    {
      return this.httpClient.get<IsolationDTO>(`${this.endpoint}/${id}`);
    }

    getAll()
    {
      return this.httpClient.get<Array<IsolationDTO>>(this.endpoint).pipe(tap(data => this.setState(data)));
    }


  add(isolation: CreateIsolationDTO)
  {
    return this.httpClient.post(this.endpoint, isolation).pipe(catchError(this.handleError));
  }

  update(id,model: UpdateIsolationDTO)
  {
    return this.httpClient.put(`${this.endpoint}/${id}`, model).pipe(catchError(this.handleError));
  }

  delete(model: ArchiveIsolationDTO)
  {
    return this.httpClient.delete(`${this.endpoint}/${model.isolationID}?comment=${model.comment}`);
  }

  CropFailed(model:ArchiveIsolationDTO)
  {
    return this.httpClient.delete(`${this.endpoint}/${model.isolationID}/paddock/sown?comment=${model.comment}`);
  }

  getBreaches(id: string)
  {
    return this.httpClient.get(`${this.endpoint}/breaches/${id}`)
  }

  getResolvedBreaches(id: string)
  {
    return this.httpClient.get(`${this.endpoint}/${id}/breaches/resolved`)
  }


  resolveBreach(isolationID: string, breachIDs: string[], comment: string)
  {
    return this.httpClient.post(`${this.endpoint}/${isolationID}/breaches/resolve`, {comment: comment, breachIDs: breachIDs})
  }

  rejectBreachResolution(breachID: string)
  {
    return this.httpClient.post(`${this.endpoint}/breaches/resolved/${breachID}/reject`, {})
  }


  addPaddock(id: string, geom: string)
  {
    return this.httpClient.post(`${this.endpoint}/${id}/paddock`, {paddockGeom: geom}).pipe(tap(() => this.getAll().subscribe()),catchError(this.handleError));
  }

  updatePaddock(id: string, geom: string)
  {
    return this.httpClient.put(`${this.endpoint}/${id}/paddock`, {paddockGeom: geom}).pipe(tap(() => this.getAll().subscribe()),catchError(this.handleError));
  }

  addSownPaddockArea(id: string, geom: string)
  {
    return this.httpClient.post(`${this.endpoint}/${id}/paddock/sown`, {sownGeom: geom}).pipe(tap(() => this.getAll().subscribe()));
  }


  getArchivedForSeason(seasonID:string)
  {
    return this.httpClient.get<Array<any>>(`${this.endpoint}/archived/${seasonID}`)
  }


   toggleDrawActive() {
    const currentValue = this.isDrawActive.getValue();
    this.isDrawActive.next(!currentValue);
  }


  setDrawActive(value: boolean) {
    this.isDrawActive.next(value);
  }


  handleError(errorRes)
  {
    let errorMessage = 'An unknown error occurred!'
    const errorDetail = errorRes.error;

    if (errorDetail === 'CANNOT_BE_GROWN_IN_TRADITIONAL_AREA') {
      errorMessage = "A non-orange carrot cannot be grown in a traditional area";
    }
    else if(errorDetail === 'PADDOCK_SIZE_LIMIT_EXCEEDED')
    {
      errorMessage = 'The limit for paddock area in this system is currently 50ha. If possible, reduce the size of the paddock to only the area intended to be sown. If the sown area for this grower will exceed 50ha please contact Esk Spatial to have this limit increased.';
    }

    return throwError(() => new Error(errorMessage));
  }


  public isolationPointStyle(
    feature: Feature<any> | RenderFeature,
    resolution,
    filter
  ) {
    let featProps = feature.getProperties();
    let styles: Array<Style> = [];
    let opacity = 1;
    let color = [240, 101, 149, 0.2];
    let stroke = [255, 255,255, 1];
    let styleKey = featProps['hasBreaches'];

    if (!this.styleCache.has(styleKey)) {
let style: Style;

      if(Boolean(styleKey))
      {
        style = new Style({
          image: new Circle({
            radius: 5,
            fill: new Fill({
              color: [255,0,0,0.5],
            }),
            stroke: new Stroke({
              width: 1,
              color: stroke,
            }),
          })

        });
      }
      else
      {
        style = new Style({
          image: new Circle({
            radius: 5,
          fill: new Fill({
            color: [0,0,230,0.5],
          }),
          stroke: new Stroke({
            width:1,
            color: stroke,
          }),})
        });
      }

      style.setStroke


      this.styleCache.set(styleKey, style);
    }

    let cachedStyle = this.styleCache.get(styleKey);

    if(cachedStyle instanceof Array)
    {
      styles.push(...cachedStyle);
    }
    else
    {
      styles.push(cachedStyle)
    }

    return styles;
  }

}

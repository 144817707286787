import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HeaderService } from './header.service';
import { MatDrawer, MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'side-bar-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Input("sideNav") drawer: MatDrawer;

  @Input("pages") pages;

  @Output("openPage") _openPage = new EventEmitter<any>();

  constructor(public headerService:HeaderService, private route: ActivatedRoute, private router:Router)
  {

  }

  ngOnInit(): void {

  }

  goBack(route:string): void {
this.headerService.navigateToParent(route);


  }


  close()
  {
    this.drawer.close();
  }

}

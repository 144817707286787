import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { RoleGuard } from './shared/guards/role.guard';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { BreachRejectedComponent } from './components/Breach-Rejected/breach-rejected.component';

const routes: Routes = [
  {
    path: 'login' ,component:LoginComponent, pathMatch: "full",
  },
  {
    path: 'forgot-password' ,component:ForgotPasswordComponent, pathMatch: "full",
  },
  {
    path: 'reset-password/:token' ,component:ResetPasswordComponent, pathMatch: "full",
  },
  {
    path: 'breach/:id/reject' ,component:BreachRejectedComponent, pathMatch: "full",
  },
  {
    path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),canActivate: [RoleGuard,AuthGuard],data: { roles: ['ADMINISTRATORS', 'OPERATIONS'] }
  },
  {
    path: 'map' ,loadChildren: () => import('./map/map.module').then(m => m.MapModule), canActivate: [AuthGuard]
  },
  {
    path: '', redirectTo: 'map', pathMatch: "full",
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import LayerGroup from 'ol/layer/Group';

@Component({
  selector: 'app-base-float',
  templateUrl: './base-float.component.html',
  styleUrls: ['./base-float.component.scss'],
  animations: [
    trigger('galleryState', [
      state('true', style({
        opacity: '1',
        gap:'1em'
      })),
      state('false', style({
        opacity: '0'
      })),
      transition('* <=> *', animate('200ms ease'))
    ])
  ],
})
export class BaseFloatComponent implements OnInit {

  isOpen = false;

  @Input() layerGroup: LayerGroup;

  layers: Array<any> = [];

  selectedBaseMap: any;

  constructor() {}

  ngOnInit(): void {
    this.layerGroup.on('change', (event) => {
      // console.log(event);
      this.setupBaseMaps();
    });

    this.setupBaseMaps();
  }

  setupBaseMaps() {
    this.layers = [];

    let layersArray = this.layerGroup.getLayersArray();
    layersArray.forEach((layer) => {
      this.layers.push(layer);
    });

    if(this.selectedBaseMap == null)
    this.selectedBaseMap = this.getVisibleBaseMap();
  }

  setBaseMapVisible(baseLayer) {
    let previousBaseMap = this.getVisibleBaseMap();
    this.selectedBaseMap = baseLayer;
    previousBaseMap.setVisible(false);
    this.selectedBaseMap.setVisible(true);
  }

  getVisibleBaseMap() {
    let layersArray = this.layerGroup.getLayersArray();

    return this.layers.find((layer) => {
      return layer.getVisible() == true;
    });
  }

  getLayerGroupVisibility() {
    return this.layerGroup.getVisible();
  }

  openGallery()
  {
    this.isOpen = !this.isOpen;
  }

  getLayerImageName(layer)
  {

    if(layer == null)
    {
      return '';
    }
    let title = layer.get('title');

     return title.replaceAll(' ', '_');
  }

  lostFocus()
  {
    //this.isOpen = false;
  }

}

import { Injectable, inject } from "@angular/core";
import { Layer } from "ol/layer";
import LayerGroup from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";
import { BingMaps, XYZ } from "ol/source";
import { BehaviorSubject, Observable, Subject, forkJoin, map, mergeMap, of, switchMap, tap, toArray } from "rxjs";
import { basemapDefinitions } from "../_helpers/basemaps";
import BaseLayer from "ol/layer/Base";
import BaseVectorLayer from "ol/layer/BaseVector";
import { Attribution } from "ol/control";
import BaseImageLayer from "ol/layer/BaseImage";
import { environment } from "src/environments/environment";
import { IsolationService } from "./isolations/isolation.service";
import { SeasonsService } from "../shared/services/seasons/seasons.service";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { CovertWKTFeature } from "../_helpers/transformations";
import { Style, RegularShape, Fill, Stroke } from "ol/style";


@Injectable({
  providedIn: 'root'
})
export class ArchivedIsolationsService
{
  private readonly isolationService: IsolationService = inject(IsolationService);
  private readonly seasonService: SeasonsService = inject(SeasonsService);

  LayerGroups = new Subject<Array<LayerGroup>>()

   isolationPinsStyle = new Style({
    image: new RegularShape({
        fill: new Fill({color: 'rgba(128,128,128,1)'}),
        points: 3,
        radius: 10,
        stroke: new Stroke({
          color: 'rgb(0,0,0)',
          width: 1
        })

    })
});

paddockStyle = new Style({
  fill: new Fill({
    color: 'rgba(128,128,128,0.75)'
  }),
  stroke: new Stroke({
    color: 'rgb(0,0,0)',
    width: 1
  })
});

   SownGeomStyle = new Style({
    fill: new Fill({
      color: 'rgba(124, 252, 0, 0.6)'
    }),
    stroke: new Stroke({
      color: '#6B8E23',  // olive green
      width: 2
    })
  });



   constructor() {


   }

   buildLayers(): Observable<Array<LayerGroup>> {
    return this.seasonService.getArchived().pipe(
      mergeMap(seasons => {
        // Create an observable for each season
        const seasonObservables = seasons.map(season => {
          return this.isolationService.getArchivedForSeason(season.seasonID).pipe(
            map(isolations => {
              let isolationsSource = new VectorSource(),
                  paddockSource = new VectorSource(),
                  SownSource = new VectorSource();

              let isolationsLayer = new VectorLayer({source:isolationsSource, properties:{title: 'Isolation Pins'}, style:this.isolationPinsStyle,}),
                  paddockLayer = new VectorLayer({source: paddockSource, properties:{title: 'Paddocks'},style:this.paddockStyle}),
                  SownLayer = new VectorLayer({source: SownSource, properties:{title: 'Sown Areas'},style:this.SownGeomStyle});

              isolations.forEach(isolation => {
                let isolationFeature = CovertWKTFeature(isolation.isolationGeom);
                isolationFeature.setProperties(isolation);
                isolationsSource.addFeature(isolationFeature);

                if(isolation.paddockGeom != null) {
                  let paddockFeature = CovertWKTFeature(isolation.paddockGeom);
                  paddockSource.addFeature(paddockFeature);
                }

                if(isolation.sownGeom != null) {
                  let feature = CovertWKTFeature(isolation.sownGeom);
                  SownSource.addFeature(feature);
                }
              });

              let layerGroup = new LayerGroup({
                layers:[isolationsLayer,paddockLayer,SownLayer],
                properties:{id:`${season.seasonID}`, title:`${season.harvestYear}`},visible:false
              });

              return layerGroup;
            })
          );
        });
        return forkJoin(seasonObservables);
      })
    );
  }

}

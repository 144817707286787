<cdk-virtual-scroll-viewport itemSize="122.5" class="example-viewport">
  <div class="list-container">
    <div (mouseover)="onHover(item.isolationID)" (click)="onClick(item.isolationID)" (mouseout)="onMouseOut()"  routerLinkActive="router-link-active"  *cdkVirtualFor="let item of items" class="example-item" [ngClass]="'status-' + item.status">
      <div>
         <span><b>{{item.name}}</b></span>
         <div *ngIf="item.hasBreaches" class="icon-wrapper invalid" style="position:absolute; bottom:-0.5rem; right:1rem;">
          <svg  xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-exclamation-mark" width="30" height="30" viewBox="0 0 28 28" stroke-width="2" stroke="white" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M12 19v.01" />
              <path d="M12 15v-10" />
          </svg>
        </div>
     </div>
     <div>
       <span>{{item.grower.name}}</span>
       <span>{{item.cropType.name | truncateText:20}}</span>
     </div>
     <div>
       <span>{{item.grower.businessName}}</span>
       <span [ngClass]="{'breach-offset': item.hasBreaches}" hasRole [showIfHasRole]="['ADMINISTRATORS']">{{item.seedCompany.companyName}}</span>
       <span class="status-label" [ngClass]="'status-' + item.status"><b>{{item.status | status}}</b></span>
   </div>
   </div>
  </div>
</cdk-virtual-scroll-viewport>

<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header >
      <mat-panel-title>
        <mat-slide-toggle
        (click)="$event.stopPropagation();"
        class="example-margin"
        [checked]="getLayerGroupVisibility()"
        (change)="setLayerGroupVisibility($event)"
        >
    </mat-slide-toggle>
    <span class="isolation-pin"></span>
    <span style="text-align:center; flex-grow:1">{{layerGroup | attribute:'title'}}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>


    <article *ngIf="layers.length > 0" class="layer-group_layers">
      <app-layer *ngFor="let layer of layers" [layer]="layer"></app-layer>
    </article>

  </mat-expansion-panel>
</mat-accordion>


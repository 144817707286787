import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, switchMap, tap } from "rxjs";
import { SeasonDTO } from "./models/season.model";
import { CreateSeasonDTO } from "./models/createSeason.model";
import { environment } from "src/environments/environment";
import { SeedCompanyDTO } from "../seed-companies/models/seed-company.model";



@Injectable({
  providedIn: 'root',
})
export class SeasonsService {

  private seasons$ = new BehaviorSubject<Array<SeasonDTO>>([]);
  private activeSeasons$ = new BehaviorSubject<Array<SeasonDTO>>(null);
  private readonly endpoint = environment.apiUrl + "/seasons"
  constructor(private httpClient: HttpClient)
  {
    this.getAll().subscribe();
    this.getCurrentSeason().subscribe();

  }

  getCurrentSeason()
  {
    return this.httpClient.get<Array<SeasonDTO>>(this.endpoint + "/current").pipe(tap(data => this.activeSeasons$.next(data)));
  }

  getState() {
    return this.activeSeasons$.asObservable();
  }

  archive(id:string)
  {
    return this.httpClient.post(`${this.endpoint}/archive/${id}`,{})
  }

  getListState()
  {
    return this.seasons$.asObservable();
  }

  setState(seasons: Array<SeasonDTO>) {
    this.seasons$.next(seasons);
  }

  get(id)
  {
    return this.httpClient.get<SeasonDTO>(`${this.endpoint}/${id}`);
  }

  getArchived()
  {
    return this.httpClient.get<Array<SeasonDTO>>(`${this.endpoint}/archived`)
  }


  getAll()
  {
    return this.httpClient.get<Array<SeasonDTO>>(this.endpoint).pipe(tap(data => this.setState(data)));
  }

  create(model: CreateSeasonDTO)
  {
    return this.httpClient.post<CreateSeasonDTO>(this.endpoint,model).pipe(switchMap(() => this.getAll()));
  }

}

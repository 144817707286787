import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserProfileDTO } from './models/user-profile.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  private endpoint = environment.apiUrl + "/profile"

  constructor(private http:HttpClient) { }

  get(): Observable<UserProfileDTO>
  {
    return this.http.get<UserProfileDTO>(this.endpoint);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Layer } from 'ol/layer';
import LayerGroup from 'ol/layer/Group';

@Pipe({
  name: 'attribute'
})
export class AttributePipe implements PipeTransform {

  transform(layer: Layer | LayerGroup, attributeName: string): any {
    if (layer && attributeName) {
      return layer.get(attributeName);
    }
    return null;
  }

}

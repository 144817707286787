<div class="layer-control">
  <mat-slide-toggle
  (click)="$event.stopPropagation();"
  class="example-margin"
  [checked]="getLayerVisibility()"
  (change)="setLayerVisibility($event)"
  >
  <img *ngIf="layerHasIcon()"  width="25px" height="25px" [src]="getLayerIcon()" alt="">
  {{ layer | attribute:'title' }}
  </mat-slide-toggle>

</div>


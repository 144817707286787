import { Injectable, inject } from '@angular/core';
import { ERROR_MESSAGE_MAP, ErrorType } from './models/auth.error.constants';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, catchError, map, of, tap, throwError } from 'rxjs';
import { TokenService } from './token.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResetPasswordDTO } from './models/resetPassword.model';
import { UserDTO } from '../users/models/user.model';
import { SignalrService } from '../signalr.service';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


interface AuthResponseData {
  token: string,
  user: UserDTO
}

interface UserDetails{
email: string,
firstName: string,
surname: string,
password: string,
}

enum appRouteMap {
  LOGIN = "/login",
}


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private readonly authApi = environment.apiUrl + "/auth";
  private readonly accountsApi = environment.apiUrl + "/accounts";

  private readonly signalRService:SignalrService = inject(SignalrService);

  private readonly isLoggedIn$ = new BehaviorSubject(false);


  constructor(private router:Router, private http:HttpClient, private tokenService:TokenService) { }

  public get isLoggedIn() {
    return this.isLoggedIn$.value;
  }

  public set isLoggedIn(value: boolean) {
    this.isLoggedIn$.next(value);
  }

  login(credentials): Observable<AuthResponseData> {

    return this.http.post<AuthResponseData>(this.authApi + '/login', {
      email: credentials.email,
      password: credentials.password
    }, httpOptions)
    .pipe(
      catchError(this.handleError), tap(resData =>
        {
          this.loginSuccessful(resData.token, resData.user.refreshToken);
        })
    )
  }

  register(userDetails:UserDetails ): Observable<any> {
    return this.http.post(this.authApi + '/register', {
      email: userDetails.email,
      password: userDetails.password,
      firstName: userDetails.firstName,
      lastName: userDetails.surname
    }, httpOptions).pipe(
      catchError(this.handleError)
    );
    }

  forgotPassword(email: string): Observable<any> {
        return this.http.post(`${this.accountsApi}/forgot-password?email=${encodeURI(email)}`, httpOptions);
      }

 adminPasswordReset(id:string, model: ResetPasswordDTO)
 {
  return this.http.post(`${this.accountsApi}/admin-password-reset/${id}`,model);
 }

  resetPassword(model: ResetPasswordDTO): Observable<any>;
  resetPassword(model: ResetPasswordDTO,token: string):Observable<any>;
  resetPassword(model: ResetPasswordDTO,token?: string)
  {
    const url = token ? `${this.accountsApi}/reset-password/${token}` : `${this.accountsApi}/reset-password/`;
    return this.http.post(url, model);

  }

  logout(){
    this.isLoggedIn$.next(false);
   this.tokenService.clearAccessToken();
   this.tokenService.clearRefreshToken();
   this.signalRService.stopConnection();
   this.router.navigateByUrl(appRouteMap.LOGIN);

  }

  refreshToken()
  {
    let token = this.tokenService.getAccessToken();
    let refreshToken = this.tokenService.getRefreshToken();

    return this.http.post<any>(this.accountsApi + `/token/refresh`, {
        accessToken: token,
        refreshToken:refreshToken
    }, httpOptions).pipe(tap(result => {
      this.loginSuccessful(result.accessToken, result.refreshToken)
    }),
      catchError(error => {
        this.logout();
        return of(false);
      })
    );
  }


private loginSuccessful(token, refreshToken)
{
  this.tokenService.saveAccessToken(token);
  this.tokenService.saveRefreshToken(refreshToken);
  this.silentRefresh();
  this.setupSignalR()
  this.isLoggedIn$.next(true);
}

private setupSignalR()
{
  if(this.signalRService.isConnected.value)
  {
    this.signalRService.stopConnection();
  }

  this.signalRService.startConnection();

}

private silentRefresh()
{
  let expiryDate = this.tokenService.getTokenExpiryDate();

  let oneMinuteBeforeExpiryDate = new Date(expiryDate.getTime() - 60 * 1000);

  let now = new Date();

  let delay = oneMinuteBeforeExpiryDate.getTime() - now.getTime();

  console.info("Refreshing in " + delay)

  setTimeout(() => {
    console.info("Silent Refresh")
    this.refreshToken().subscribe()
  }, delay);
}


  handleError(errorRes)
{
  let errorMessage = 'An unknown error occurred!'
  const errorDetail = errorRes.error && errorRes.error.detail;

  if (errorDetail) {
    errorMessage = ERROR_MESSAGE_MAP[errorDetail] || errorMessage;
  }

  if (errorDetail === ErrorType.INVALID_REQUEST) {
    this.logout();
  }

  return throwError(() => new Error(errorMessage));
}

}

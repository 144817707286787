export enum TreeMapperCustomEvents{
  PropertyFeaturesSelected = 'PROPERTY_FEATURES_SELECTED',
  FencesLoaded = 'FENCES_LOADED',
  PaddockFeatureCreated = 'PADDOCK_FEATURE_CREATED',
  FenceFeatureCreated = 'FENCE_FEATURE_CREATED'
}

export enum ReportingToolType {
  CROP_REPORT = 1,
  NPV_CALCULATOR = 2,
  TOPEX_TOOL = 3
}

export enum ProcessState {
  Not_Processing = 1,
  Creating_Property = 2,
  Creating_Paddock = 3,
  Clipping_Layers = 4,
  Generating_Report = 5
}

export enum DrawType
{
  Point = "Point",
  LineString = "LineString",
  Polygon = "Polygon",
  Circle = "Circle"

}


<div *ngIf="isolationData$ | async as isolation" style="display:grid; gap:1rem; padding-block-start:0.5rem; ">

  <span style="text-align: center; position:relative">{{isolation.name}}</span>

  <section class="container">

    <div class="column">
        <span appTooltip="Grower Name" tooltipPosition="left">{{isolation.grower.name}}</span>
        <span appTooltip="Business Name" tooltipPosition="left"><b>{{isolation.grower.businessName}}</b></span>
        <span hasRole [showIfHasRole]="['ADMINISTRATORS']">{{isolation.seedCompany.companyName}}</span>
        <span appTooltip="Isolation Status" tooltipPosition="left" class="status-label" [ngClass]="'status-' + isolation.status">{{isolation.status | status }}</span>

    </div>
    <div class="column place-end">
      <button appTooltip="Edit Isolation Details" tooltipPosition="left" class="button button--black-outline button--xs"  (click)="update(isolation.isolationID)"><i class="fa fa-edit"></i></button>
      <button [appTooltip]="isolation.cropType.name" tooltipPosition="left" class="button button--black-outline button--xs" (click)="test(isolation.cropType.cropTypeID)">{{isolation.cropType.name | truncateText:20}}</button>
      <button class="button button--black-outline button--xs" (click)="zoomToIsolation(isolation.isolationID,false)">Zoom To</button>
    </div>
  </section>
  <app-breach-list [breaches]="breachData$ | async" (breachClicked)="showBreachInfo($event,isolation)"></app-breach-list>

  <ng-container  *ngIf="(resolvedBreachData$ | async) as data"
  >
  <resolved-breach-list *ngIf="data.length > 0" [resolvedBreaches]="data" (breachClicked)="showResolvedBreachInfo($event,isolation)"></resolved-breach-list>
</ng-container>

  <section class="container">
    <div class="column place-end">Pin Booked:</div>
    <div class="column"><span>{{isolation.createdDate | displayDate}}</span></div>
  </section>
  <section *ngIf="isolation.status >= isolationStatus.PaddockCreated" class="container">
    <div class="column place-end">Paddock Created:</div>
    <div class="column"><span>{{isolation.paddockCreatedDate | displayDate}}</span></div>
  </section>
  <section *ngIf="isolation.status >= isolationStatus.PaddockSown" class="container">
    <div class="column place-end">Paddock Sown:</div>
    <div class="column"><span>{{isolation.sownDate | displayDate}}</span></div>
  </section>
  <section class="actions">
    <button hasRequiredStatus [status]="isolation.status" [requiredStatus]="isolationStatus.Created" (click)="createPaddock()" class="button button--green-fill button--xl button--equal-width">Create Paddock</button>
    <button hasRequiredStatus [status]="isolation.status" [requiredStatus]="isolationStatus.PaddockCreated" (click)="editPaddock()" class="button button--yellow-fill button--xl button--equal-width">Edit Paddock</button>
    <button hasRequiredStatus [status]="isolation.status" [requiredStatus]="isolationStatus.PaddockCreated" (click)="sowPaddock(isolation)" class="button button--green-fill button--xl button--equal-width">Sow Paddock</button>
    <button hasRequiredStatus [status]="isolation.status" [requiredStatus]="isolationStatus.PaddockSown" (click)="markAsFailed(isolation)" class="button button--black-outline button--xl button--equal-width">Mark as Failed</button>
    <button hasRole [showIfHasRole]="['ADMINISTRATORS','OPERATIONS']" (click)="delete(isolation)" class="button button--danger button--xl button--equal-width">Delete Isolation</button>

    <button hasRole [showIfHasRole]="['ADMINISTRATORS']" (click)="viewHistory(isolation.isolationID)" class="button button--blue button--xl button--equal-width">History</button>

  </section>
</div>

import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { TokenService } from 'src/app/shared/services/authentication/token.service';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css'],
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
        maxHeight: '500px',
        boxShadow: 'inset 2px 2px 5px 1px rgba(0, 0, 0, 0.5);'
      })),
      state('closed', style({
        opacity: 0,
        maxHeight: '0',
      })),
      transition('open => closed', [
        animate('0.1s')
      ]),
      transition('closed => open', [
        animate('0.25s')
      ]),
    ]),
  ],
})
export class GroupComponent implements OnInit {

  @Input() NavGroupName: string = 'Default'

  @Input() NavGroupRoutes: {displayName: string, route: string, restricted:boolean, roles: string[]}[] = [{displayName: 'Parent Codenames', route: 'reference-data/crop-types',restricted: true,roles: ['ADMINISTRATORS','OPERATIONS']},  {displayName: 'Varieties', route: 'reference-data/varieties', restricted: true,roles: ['ADMINISTRATORS','OPERATIONS']},
  {displayName: 'Seasons', route: 'reference-data/seasons', restricted: true,roles: ['ADMINISTRATORS']},
  {displayName: 'Types', route: 'reference-data/types', restricted: true,roles: ['ADMINISTRATORS']}
,{displayName: 'Cultivar Colours', route: 'reference-data/cultivar-colours', restricted: true,roles: ['ADMINISTRATORS']},
  {displayName: 'Species', route: 'reference-data/species', restricted: true, roles: ['ADMINISTRATORS']}]

  isOpen = false;

  constructor(private router: Router, public tokenService: TokenService) {

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {

      this.isOpen =  this.NavGroupRoutes.some(routeObject =>
        this.router.isActive('/admin/' + routeObject.route, {paths: 'subset', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'})
      )
    });

   }

   ngOnInit(): void {
    this.isOpen =  this.NavGroupRoutes.some(routeObject =>
      this.router.isActive('/admin/' + routeObject.route, {paths: 'subset', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'})
    );
   }


  toggle() {
    this.isOpen = !this.isOpen;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatDividerModule} from '@angular/material/divider'
import {MatListModule} from '@angular/material/list'
import {MatCardModule} from '@angular/material/card'
import {MatIconModule} from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PasswordComplexityComponent } from './components/password-complexity/password-complexity.component';

import { InitialsPipe } from './pipes/initials.pipe';
import { HasRoleDirective } from './directives/has-role.directive';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { PluralPipe } from './pipes/plural.pipe';
import { IsolationStatusDirective } from './directives/required-status.directive';
import { DisplayDatePipe } from './pipes/display-date.pipe';
import { RelationalDiagramComponentComponent } from './components/dialogs/relational-diagram-component/relational-diagram-component.component';


import { RoleNamePipe } from './pipes/display-roles.pipe';
import { AuditEntryComponent } from './components/dialogs/audit-entry/audit-entry.component';
import { StatusPipe } from './pipes/status.pipe';
import { CreateGrowerDialogComponent } from './services/growers/dialogs/create-grower-dialog/create-grower-dialog.component';
import { NotificationToastComponent } from './toast/notification-toast/notification-toast.component';
import { PasswordResetComponent } from './components/dialogs/password-reset/password-reset.component';
import { ErrorComponent } from './components/dialogs/error/error.component';
import { TooltipDirective } from './directives/tooltip/tooltip.directive';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DeleteConfirmationComponent } from './components/dialogs/delete-confirmation/delete-confirmation.component';
import { ConfirmationComponent } from './components/dialogs/confirmation/confirmation.component';
import { AttributePipe } from './pipes/attribute.pipe';
import { TruncateTextPipe } from './pipes/truncate-text.pipe';


@NgModule({
  declarations: [
    PasswordResetComponent,
    PasswordComplexityComponent,
    InitialsPipe,
    HasRoleDirective,
    IsolationStatusDirective,
    TooltipDirective,
    PluralPipe,
    DisplayDatePipe,
    RoleNamePipe,
    RelationalDiagramComponentComponent,
    AuditEntryComponent,
    StatusPipe,
    CreateGrowerDialogComponent,
    NotificationToastComponent,
    ErrorComponent,
    DeleteConfirmationComponent,
    ConfirmationComponent,
    AttributePipe,
    TruncateTextPipe
  ],
  imports: [
    CommonModule,
    ScrollingModule,
    MatInputModule,
    MatSelectModule,
    MatTreeModule,
    MatMenuModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSortModule,
    MatPaginatorModule,
    MatTableModule,
    MatDialogModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatRadioModule,
    MatCheckboxModule,
    MatProgressBarModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatDividerModule,
    MatListModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    NgxSkeletonLoaderModule
  ],exports:[
    MatInputModule,
    MatSelectModule,
    MatTreeModule,
    MatMenuModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSortModule,
    MatPaginatorModule,
    MatTableModule,
    MatDialogModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatRadioModule,
    MatCheckboxModule,
    MatProgressBarModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    PasswordResetComponent,
    PasswordComplexityComponent,
    MatCardModule,
    MatDividerModule,
    MatListModule,
   InitialsPipe,
   DisplayDatePipe,
   HasRoleDirective,
   MatSnackBarModule,
   PluralPipe,
   IsolationStatusDirective,
   CommonModule,
   MatButtonModule,
   MatIconModule,
   RoleNamePipe,
   AuditEntryComponent,
   StatusPipe,
   TooltipDirective,
   NgxSkeletonLoaderModule,
   DeleteConfirmationComponent,
   AttributePipe,
   TruncateTextPipe
  ]
})
export class SharedModule { }

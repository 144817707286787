import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-confirmation-dialog',
  templateUrl: './delete-confirmation-dialog.component.html',
  styleUrls: ['./delete-confirmation-dialog.component.css']
})
export class DeleteConfirmationDialogComponent {

  commentControl = new FormControl('', [Validators.required]);

constructor(public dialogRef: MatDialogRef<DeleteConfirmationDialogComponent>,) {

}

  onCancelClick(): void {
    this.dialogRef.close();
  }

  confirmDeletion() {
    if (this.commentControl.valid) {
      this.dialogRef.close({comment: this.commentControl.value});
    } else {
      this.commentControl.markAsTouched();
    }
  }

}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, switchMap, tap } from "rxjs";
import { CreateUserDTO } from "./models/create-user.model";
import { UserDTO } from "./models/user.model";
import { UpdateUserDTO } from "./models/update-user.model";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root',
  })
  export class UsersService {

    private users$ = new BehaviorSubject<Array<UserDTO>>([]);

    private readonly endpoint = `${environment.apiUrl}/users`;

    constructor(private httpClient: HttpClient)
    {
      this.getAll().subscribe();

    }

    getState()
    {
        return this.users$.asObservable()
    }

    setState(growers: Array<UserDTO>) {

      this.users$.next(growers);
    }

    getAll()
    {
      return this.httpClient.get<Array<UserDTO>>(this.endpoint).pipe(tap(data => this.setState(data)));
    }

    get(id)
    {
      return this.httpClient.get<UserDTO>(`${this.endpoint}/${id}`);
    }

    create(user: CreateUserDTO)
    {
      return this.httpClient.post(this.endpoint, user).pipe(switchMap(data => this.getAll()));
    }

    delete(userID: string)
    {
        return this.httpClient.delete(`${this.endpoint}/${userID}`).pipe(switchMap(data => this.getAll()));
    }

    update(id: string, model:UpdateUserDTO)
    {
      return this.httpClient.put(`${this.endpoint}/${id}`,model).pipe(switchMap(data => this.getAll()));
    }

    resetPassword(userID:string, password: string)
    {
      return this.httpClient.post(`https://dev.seedisolation.eskmapping.com.au/api/accounts/admin-password-reset/${encodeURIComponent(userID)}?password=${password}`,{}).pipe();
    }

  }

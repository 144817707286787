import * as olProj from 'ol/proj';

import {register} from 'ol/proj/proj4';
import proj4 from 'proj4';


proj4.defs("EPSG:28355","+proj=utm +zone=55 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
register(proj4);


export var mapProjEPSG = "EPSG:28355"
var map_proj = olProj.get(mapProjEPSG);

if(!map_proj.getWorldExtent()){//Aus
    map_proj.setWorldExtent([112.85, -43.7, 153.69, -9.86]);
}

var projExt = olProj.transformExtent(map_proj.getWorldExtent(), olProj.get('EPSG:4326'), map_proj);

map_proj.setExtent(projExt);

<li [class.group-open]="isOpen" (click)="toggle()">
  <span class="admin-sidebar-link">Reference Data</span>
  <ul [class.group-name--open]="isOpen" [@openClose]="isOpen ? 'open' : 'closed'">
    <div (click)="$event.stopPropagation()" *ngFor="let route of NavGroupRoutes">
      <div (click)="$event.stopPropagation()" *ngIf="tokenService.hasAnyGivenRoles(route.roles)">
        <a  class="admin-sidebar-link" [routerLink]="route.route" routerLinkActive="router-link-active">{{route.displayName}}</a>
      </div>
    </div>
  </ul>
</li>

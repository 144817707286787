<div class="popup-header">
  <span><b>{{data.isolationName|truncateText:15}}</b></span>
  <button (click)="close($event)" class="ol-popup-closer"><span><i class="fa fa-times"></i></span></button>
</div>
<mat-divider></mat-divider>
<div class="popup-content">
  <table>
    <tbody>
      <tr>
        <td>Grower Name</td>
        <td>{{data.growerName|truncateText:15}}</td>
      </tr>
      <tr>
        <td>Code Name</td>
        <td>{{data.codeName|truncateText:15}}</td>
      </tr>
      <tr>
        <td>Status</td>
        <td>{{data.status}}</td>
      </tr>
      <tr>
        <td>Pinned Date</td>
        <td>{{data.pinnedDate | displayDate}}</td>
      </tr>
    </tbody>
  </table>
</div>

import { Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HostBinding, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { CustomValidators } from 'src/app/_helpers/customValidators';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { TokenService } from 'src/app/shared/services/authentication/token.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  error: string = '';
  public version: string = '1.2.3-dev.20230904';

  constructor(private authService: AuthenticationService, private tokenService:TokenService,  private router:Router, private route:ActivatedRoute) {

    this.version = environment.version;
  }

  ngOnInit(): void {
    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [CustomValidators.required,CustomValidators.email]),
      password: new UntypedFormControl(null, CustomValidators.required),
    });

    this.loginForm.valueChanges.subscribe((changes) => {
      this.error = null;
    });
  }

  onSubmit(): void {

    this.authService.login(this.loginForm.getRawValue()).subscribe({
      next: user => {

        const returnUrl = this.route.snapshot.queryParams['returnUrl'];

        if (returnUrl) {

          this.router.navigateByUrl(returnUrl);
        } else if (this.tokenService.hasAnyGivenRoles(['ADMINISTRATORS','OPERATIONS'])) {

          this.router.navigate(['admin']);
        } else {

          this.router.navigate(['']);
        }
      },
      error: error => this.error = error.message
    });

  }


}

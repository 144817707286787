import { Directive, Input, Renderer2, ElementRef, OnInit } from '@angular/core';
import { TokenService } from '../services/authentication/token.service';

@Directive({
  selector: '[hasRole]',
})
export class HasRoleDirective implements OnInit {
  @Input() enableIfHasRole: string[];
  @Input() showIfHasRole: string[];

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private tokenService: TokenService,
  ) {}

  ngOnInit() {
    // Control enabling/disabling of the element
    if (this.enableIfHasRole) {
      if (this.tokenService.hasAnyGivenRoles(this.enableIfHasRole)) {
        this.renderer.removeAttribute(this.element.nativeElement, 'disabled');
      } else {
        this.renderer.setAttribute(this.element.nativeElement, 'disabled', 'true');
      }
    }

    // Control visibility of the element
    if (this.showIfHasRole) {
      if (this.tokenService.hasAnyGivenRoles(this.showIfHasRole)) {
        this.renderer.removeStyle(this.element.nativeElement, 'display');
      } else {
        this.renderer.setStyle(this.element.nativeElement, 'display', 'none');
      }
    }
  }
}

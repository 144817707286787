import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import {Group, Layer, Tile} from 'ol/layer';
import OlMap from 'ol/Map';
import { ProjectionLike } from "ol/proj";
import * as olProj from 'ol/proj';
import {

  OSM,

  Vector,

  Vector as VectorSource,


} from 'ol/source';
import View from "ol/View";
import { mapProjEPSG } from "src/app/_helpers/projection_setup";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import BaseLayer from "ol/layer/Base";
import LayerGroup from "ol/layer/Group";
import { populateLayerGroup } from 'src/app/_helpers/LayerBuilder';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { Draw, Modify } from 'ol/interaction';
import { ModifyEvent } from 'ol/interaction/Modify';
import RenderFeature from 'ol/render/Feature';
import { DrawEvent } from 'ol/interaction/Draw';
import { IsolationService } from '../../isolation.service';
import { Intersects } from 'src/app/_helpers/transformations';
import { ToastService } from 'src/app/shared/services/toast.service';
import { Attribution, ScaleLine } from 'ol/control';

@Component({
  selector: 'app-create-paddock',
  templateUrl: './create-paddock.component.html',
  styleUrls: ['./create-paddock.component.css']
})
export class CreatePaddockComponent implements OnInit {

  @ViewChild('paddockMap', {static:true}) mapReference: ElementRef;
  private proj : ProjectionLike | null = olProj.get(mapProjEPSG);
  map: OlMap;

  private drawInteraction: Draw;
  private modifyInteraction: Modify;
  public drawLayer: VectorLayer<any>;
  private paddock: Feature<any> | RenderFeature;
  public hasError = true;

  public baseMapGroup = new LayerGroup({
    layers: [],
    title: 'BackgroundMaps',
    fold: 'open'
  } as any);


  constructor(
    private zone: NgZone,
    @Inject(MAT_DIALOG_DATA) private data:{feature:Feature<Point>},
    public dialogRef: MatDialogRef<CreatePaddockComponent>,
    private isolationService: IsolationService,
    private toastService:ToastService
    )
  {


    const isolationLayer = new VectorLayer({source: new VectorSource({features: [this.data.feature]}),style: (feature, resolution) => {return this.isolationService.isolationPointStyle(feature,resolution,'')} })

    this.map = new OlMap({
      layers: [this.baseMapGroup,isolationLayer],
      controls: [
        new Attribution(),
        new ScaleLine({
          bar: true,
          minWidth: 150
        })
      ],
      view: new View({
      center: data.feature.getGeometry().getCoordinates(),
      projection: this.proj == null ? null : this.proj,
      zoom: 13,
      maxZoom: 23,

    })});

    this.drawLayer = new VectorLayer({
      source: new VectorSource()
    });

    this.map.addLayer(this.drawLayer);


    this.drawInteraction = new Draw({
      type: 'Polygon',
      source: this.drawLayer.getSource()
    });


    this.drawInteraction.on('drawend',(event:DrawEvent) => {

      this.zone.run(() => {
        if(!Intersects(event.feature, this.data.feature))
        {
          this.toastService.showError("Paddock must contain the booked pin!")
          this.hasError = true;
        }
        else
        {
          this.hasError = false;


          this.paddock = event.feature;

          this.map.removeInteraction(this.drawInteraction);
          this.map.addInteraction(this.modifyInteraction);
        }
      })


    })

    let drawVectorSource = this.drawLayer.getSource() as VectorSource;

    drawVectorSource.on('addfeature', (feature) => {

      if(this.hasError)
      {
        drawVectorSource.clear();
      }

    } )

    this.modifyInteraction = new Modify({source: this.drawLayer.getSource()});

    this.modifyInteraction.on('modifyend',(event:ModifyEvent) => {

      let _feature = event.features.pop();

      this.paddock = _feature == undefined ? this.paddock : _feature;
    })


    this.map.addInteraction(this.drawInteraction);


  }


  drawEnd(drawEvent)
  {

  }

  onBaseMapChange(selectedLayer: any) {
      this.baseMapGroup.getLayers().clear();

      this.baseMapGroup.getLayers().push(selectedLayer);
  }


  clear()
  {
    let drawVectorSource = this.drawLayer.getSource() as VectorSource;

    drawVectorSource.clear();

    this.hasError = true

    this.map.addInteraction(this.drawInteraction);
    this.map.removeInteraction(this.modifyInteraction);
  }

  ngOnInit(): void {

    this.zone.runOutsideAngular(() => {
      this.map.setTarget(this.mapReference.nativeElement);
    })
  }

  onSubmit()
  {
    this.dialogRef.close(this.paddock);
  }

}


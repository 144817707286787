<h2 mat-dialog-title>Resolved Breaches for {{data.seedCompany.companyName}}</h2>
<mat-dialog-content>

  <p>Total Breaches: <b>{{ data.breachCount }}</b></p>
  <p>Contact Email: <b>{{data.seedCompany.contactEmail }}</b></p>
  <p>Contact Phone: <b>{{data.seedCompany.contactPhone }}</b></p>

<mat-divider></mat-divider>
  <table mat-table [dataSource]="data.resolvedBreaches" matSort>
    <!-- Position Column -->
    <ng-container matColumnDef="reason">
     <th mat-header-cell *matHeaderCellDef data-label="Reason"  sortActionDescription="Sort by number"> Resolved Reason</th>
     <td mat-cell appTooltip [appTooltip]="element.reason"
     tooltipPosition="left" *matCellDef="let element" data-label="Reason"> {{element.reason}} </td>
   </ng-container>

   <ng-container matColumnDef="resolvedDate">
     <th mat-header-cell *matHeaderCellDef  sortActionDescription="Sort by number"> Resolved Date </th>
     <td mat-cell *matCellDef="let element"> {{element.resolvedDate | displayDate}} </td>
   </ng-container>
               <mat-header-row *matHeaderRowDef="displayedColumns" class="mat-header-row--sticky"></mat-header-row>
               <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
             </table>
</mat-dialog-content>

import { Component, EventEmitter, Output } from '@angular/core';

import { InOut, InOut2, fadeInOut } from 'src/app/shared/animations/animations';

@Component({
  selector: 'app-isolation-toolbox-overlay',
  templateUrl: './isolation-toolbox-overlay.component.html',
  styleUrls: ['./isolation-toolbox-overlay.component.css'],
  animations:[fadeInOut,InOut, InOut2 ]
})
export class IsolationToolboxOverlayComponent {

  @Output() disableTool = new EventEmitter();


  hideOverlay(): void {
    this.disableTool.emit();
  }

}

import { Injectable } from "@angular/core";
import { Map } from "ol";
import { Layer } from "ol/layer";
import LayerGroup from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";
import { BingMaps, XYZ } from "ol/source";
import { BehaviorSubject, Subject } from "rxjs";
import { basemapDefinitions } from "../_helpers/basemaps";
import BaseLayer from "ol/layer/Base";
import BaseVectorLayer from "ol/layer/BaseVector";
import { Attribution } from "ol/control";
import BaseImageLayer from "ol/layer/BaseImage";

type Base = {
  active: boolean;
  display_name: string;
  epsg: number;
  id: number | string;
  matrix_set: string;
  max_zoom: number;
  min_zoom: number;
  name: string;
  type: 'Bing' | 'XYZ';
  options: string;
  order: number;
  visible: boolean;
};

export type BingLayer = Base & {
  bing_key: string;
  bing_imagery_set: string;
  type: 'Bing'
};

export type XYZLayer = Base & {
  attribution_text: string;
  attribution_url: string;
  extent_wgs84_ulx?: number;
  extent_wgs84_uly?: number;
  extent_wgs84_lrx?: number;
  extent_wgs84_ury?: number;
  url: string;
  version_id: string;
  type:'XYZ';
};


export type BaseLayerType = BingLayer | XYZLayer;

@Injectable({
  providedIn: 'root'
})
export class BasemapsService
{
   private readonly default_epsg = 3857;
   private readonly default_min_zoom = 0;
   private readonly default_max_zoom = 18;




   constructor() {


   }

   buildLayers(layerDefinitions: BaseLayerType[] = basemapDefinitions): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
        const layers = [];
        const promises = [];

        layerDefinitions.forEach(layerDefinition => {
            switch (layerDefinition.type) {
                case 'Bing':
                    promises.push(this.buildBingLayer(layerDefinition));
                    break;

                case "XYZ":
                    promises.push(this.buildXYZLayer(layerDefinition));
                    break;
            }
        });

        try {
            const resolvedLayers = await Promise.all(promises);
            resolvedLayers.forEach(layer => layers.push(layer));
            resolve(layers);
        } catch (error) {
            reject(error);
        }
    });
}


   private buildXYZLayer(definition: XYZLayer)
   {
      let layer = new TileLayer({
        className: 'BaseLayers',
        source: new XYZ({
            url: definition.url,
            projection : 'EPSG:'+(definition.epsg?definition.epsg:this.default_epsg),
            attributions: (definition.attribution_text?definition.attribution_text:''),
            minZoom: (definition.min_zoom?definition.min_zoom:this.default_min_zoom),
            maxZoom: (definition.min_zoom?definition.min_zoom:this.default_max_zoom)
        }),
        visible:definition.visible,
        properties:{
          type: 'base',
          title: definition.display_name
        }
        });

        if(layer && definition.attribution_url)
        {
          this.getAndSetCopyright(definition.attribution_url, layer);
        }

        return layer;
    }

    private buildBingLayer(definition: BingLayer)
    {
      let layer = new TileLayer({
        className: 'BaseLayers',
        visible: definition.visible,
        preload: Infinity,
        source: new BingMaps({
            key: definition.bing_key,
            imagerySet: definition.bing_imagery_set,
        } ),
        properties:{
          type: 'base',
          title: definition.display_name
        }
    });



    return layer;
    }

    private async getAndSetCopyright(attribution_url: RequestInfo, layer: BaseVectorLayer<any, any> | BaseImageLayer<any, any>) {
      try {
          const response = await fetch(attribution_url);


          if (!response.ok) {
              throw new Error(`Failed to fetch: ${response.statusText}`);
          }


          const cr = await response.json();

          if (cr.copyrightText) {
              const attrib = new Attribution();
              attrib.setProperties({ html: cr.copyrightText });
              layer.getSource().setAttributions(cr.copyrightText);
          }

      } catch (error) {
          console.error("Error fetching and setting copyright:", error);
      }
  }

}

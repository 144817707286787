import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Map } from 'ol';

import { Control, Zoom } from 'ol/control';

@Component({
  selector: 'map-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.css']
})
export class ZoomComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() map:Map

  control: Control;


  constructor(private elRef:ElementRef) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    let zoomInIcon = document.createElement('i');
    zoomInIcon.className = 'fa fa-plus';

    let zoomOutIcon = document.createElement('i');
    zoomOutIcon.className = 'fa fa-minus';

    this.control = new Zoom(
      {
        target: this.elRef.nativeElement.parentNode,
        className: 'esk-tool',
        zoomInClassName: 'esk-tool-zoom-in',
        zoomOutClassName: 'esk-tool-zoom-out',
        zoomInLabel: zoomInIcon,
        zoomOutLabel: zoomOutIcon
       });

      this.map.addControl(this.control);
  }

  ngOnDestroy(): void {
    if(this.control == null)
    return;

    this.map.removeControl(this.control);
  }
  }



import { MultiPoint } from 'ol/geom';
import LineString from 'ol/geom/LineString';
import RenderFeature from 'ol/render/Feature';
import { Circle, Fill, RegularShape, Stroke, Style, Text } from 'ol/style';

export var styleMap: Map<string, Style | Style[]> = new Map();



let styleCache: Map<string, Style | Style[]> = new Map();


export const sownAreaStyle = new Style({
  fill: new Fill({
    color: 'rgba(124, 252, 0, 0.6)'  // light green with some opacity
  }),
  stroke: new Stroke({
    color: '#6B8E23',  // olive green
    width: 2
  })
});


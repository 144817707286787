import { OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, NgZone, Component, ChangeDetectorRef, inject, ComponentRef, ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, take, tap } from 'rxjs/operators';
import { IsolationService } from './isolations/isolation.service';
import { MapService } from './map.service';
import { SidebarService } from './sidebar/sidebar.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { InOut,  InOut2,  fadeInOut } from '../shared/animations/animations';
import { Select } from 'ol/interaction';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { AttributeOverlayComponent } from './components/tools/attribute-overlay/attribute-overlay.component';
import { AppSettingsService } from '../shared/services/AppSettings.service';
import { FormControl } from '@angular/forms';
import { SignalrService } from '../shared/services/signalr.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  animations:[fadeInOut,InOut, InOut2 ]
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('map', {static:true}) map: ElementRef;
  @ViewChild('addAttributeOverlayHere', {static : false, read : ViewContainerRef}) attributeOverlayTarget: ViewContainerRef;
  private attributeComponentRef: ComponentRef<AttributeOverlayComponent>;
  private navigationSubscription;
  private readonly dialog:MatDialog = inject(MatDialog);
  private readonly appSettings:AppSettingsService = inject(AppSettingsService);
  public hideEnvironmentWarning:FormControl;
  isolationToolState$: Observable<boolean>;

  collapsed: false;
  select: Select;

  testSystemTermsAccepted$:Observable<boolean>;
  isProduction = environment.production;


  constructor(public mapService: MapService,private zone: NgZone, private router:Router, public route:ActivatedRoute, public sidebarService:SidebarService, public isolationService: IsolationService,    private cdr: ChangeDetectorRef, private signalRService:SignalrService ) {
    this.isolationToolState$ = isolationService.isDrawActive.asObservable().pipe(tap(state => state ? this.sidebarService.close()  : this.sidebarService.open() ))

this.hideEnvironmentWarning = new FormControl(false);

    this.navigationSubscription = this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe(() => {
        this.cdr.detectChanges();
        this.mapService.updateSize();
        this.mapService.getMap().render();
    });

    this.mapService.historicalLayersLoaded.pipe(filter(v => v == true)).subscribe(() => {
      this.mapService.historicalSelect.on('select', (event) => {

        if(event.selected.length > 0)
        {
          this.onFeatureSelected(event.selected[0])
        }

        else
        {
          this.attributeComponentRef.destroy();
        }
      })
    })

    this.testSystemTermsAccepted$ = this.appSettings.getTestTermsAccepted();

    this.signalRService.isConnected.subscribe(connected => {
      if(!connected)
      {
        this.isolationService.unsetSignalRHook();
      }
      else
      {
        this.isolationService.setSignalRHook();
      }
    })
  }

  ngOnInit(): void {

  }

  onFeatureSelected(feature)
  {

    this.zone.run(()=> {
      this.attributeComponentRef = this.attributeOverlayTarget.createComponent(AttributeOverlayComponent);
      this.attributeComponentRef.instance.map = this.mapService.getMap();
      this.attributeComponentRef.instance.selectedFeature = feature;
      this.attributeComponentRef.instance.attributeAliasMap = null;
    })

  }

  onAcceptTestTerms() {
this.appSettings.setIsTestTermsAccepted(this.hideEnvironmentWarning.value);
  }

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      this.mapService.setTarget(this.map.nativeElement);
    })

    this.isolationService.getAll().subscribe();


  }

  ngOnDestroy(): void {
    this.mapService.setTarget(null);
    this.mapService.getMap().removeInteraction(this.select);
    this.isolationService.OnNavigateAwayFromMap();
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}

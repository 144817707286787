import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { AdminComponent } from './admin.component';
import { SharedModule } from '../shared/shared.module';
import { GroupComponent } from './shared/sidebar/group/group.component';
import { BreadcrumbsComponent } from './shared/breadcrumbs/breadcrumbs.component';


@NgModule({
  declarations: [
    SidebarComponent,
    BreadcrumbsComponent,
    AdminComponent,
    GroupComponent,

  ],
  imports: [
    AdminRoutingModule,
    SharedModule
  ]
})
export class AdminModule { }


 export enum ErrorType {
  INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
  EMAIL_EXISTS = "EMAIL_EXISTS",
  EMAIL_NOT_CONFIRMED = "EMAIL_NOT_CONFIRMED",
  ACCOUNT_DISABLED = "ACCOUNT_DISABLED",
  INVALID_REQUEST = "INVALID_REQUEST",
  CANNOT_GROW_TRADITIONAL_AREA = "CANNOT_BE_GROWN_IN_TRADITIONAL_AREA"
}

export const ERROR_MESSAGE_MAP = {
  [ErrorType.INVALID_CREDENTIALS]: "Invalid Login Credentials",
  [ErrorType.EMAIL_EXISTS]: "An Account Already Exists With That Email",
  [ErrorType.EMAIL_NOT_CONFIRMED]: "This user has not yet been activated. Please check your email to find the activation email before logging in.",
  [ErrorType.ACCOUNT_DISABLED]: "Your account has been disabled, please contact an admin",
  [ErrorType.INVALID_REQUEST]: "Invalid client request",
  [ErrorType.CANNOT_GROW_TRADITIONAL_AREA]: "A non-orange carrot cannot be grown in a traditional area!"
};

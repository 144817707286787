import { Component, inject } from '@angular/core';
import { MapService } from '../map.service';
import { ArchivedIsolationsService } from '../archivedIsolations.service';
import { SeasonsService } from 'src/app/shared/services/seasons/seasons.service';

@Component({
  selector: 'app-layers',
  templateUrl: './layers.component.html',
  styleUrls: ['./layers.component.scss']
})
export class LayersComponent {

  archivedSeasons;
  basemapsLoaded$;
  historicalLayersLoaded$

  constructor(public mapService:MapService, public seasonService: SeasonsService) {

    this.archivedSeasons = seasonService.getArchived()
    this.basemapsLoaded$ = mapService.baseMapsLoaded.asObservable();
    this.historicalLayersLoaded$ = mapService.historicalLayersLoaded.asObservable()
  }
}

import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateChild,
  UrlTree
} from '@angular/router';
import { catchError, map, Observable, of, take } from 'rxjs';
import { Injectable, inject } from '@angular/core';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { TokenService } from '../services/authentication/token.service';



export function AuthGuard(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean> | boolean | UrlTree {
  const authService = inject(AuthenticationService);
  const tokenService = inject(TokenService);
  const router = inject(Router);

  const isLoggedIn = authService.isLoggedIn;

  if (isLoggedIn) {
      return true;
  }
  else
  {
    let tokenExists = tokenService.getAccessToken();

    if(tokenExists == null)
    {
      return router.createUrlTree(['/login']);
    }

    const isTokenExpired = tokenService.isTokenExpired();

    if(isTokenExpired)
    {
      return authService.refreshToken().pipe(
        map(newToken => {
            return true;
        }),
        catchError(error => {
            return of(router.createUrlTree(['/login']));
        }));

    }

    authService.isLoggedIn = true;

    return of(true);
  }

  return router.createUrlTree(['/login']);
}

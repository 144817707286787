import { Component, inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomValidators } from 'src/app/_helpers/customValidators';
import { SeedCompanyService } from '../../../seed-companies/seed-companies.service';
import { ToastService } from '../../../toast.service';
import { GrowersService } from '../../growers.service';
import { CreateGrowerDTO } from '../../models/createGrower.model';

@Component({
  selector: 'app-create-grower-dialog',
  templateUrl: './create-grower-dialog.component.html',
  styleUrls: ['./create-grower-dialog.component.css']
})
export class CreateGrowerDialogComponent {
  public readonly seedCompanyService: SeedCompanyService = inject(SeedCompanyService);
  public readonly growerService: GrowersService = inject(GrowersService);
  private readonly toastService: ToastService = inject(ToastService);
  form:UntypedFormGroup;

  constructor(private dialogRef:MatDialogRef<CreateGrowerDialogComponent>) {
   this.form = new UntypedFormGroup({
      'name': new UntypedFormControl(null, CustomValidators.required),
      'businessName': new UntypedFormControl(null, CustomValidators.required),
      'contactNumber': new UntypedFormControl(null, CustomValidators.required),
      'contactEmail': new UntypedFormControl(null, [
        CustomValidators.required,
        CustomValidators.email,
      ]),
      'seedCompanyID':new UntypedFormControl(null)
    });

  }


  onSubmit()
  {
    let model = new CreateGrowerDTO();

    model.name = this.form.get('name').value;
    model.businessName = this.form.get('businessName').value;
    model.contactNumber = this.form.get('contactNumber').value;
    model.contactEmail = this.form.get('contactEmail').value;
    model.seedCompanyID = this.form.get('seedCompanyID').value;

    this.growerService.create(model).subscribe((id) => {
      this.toastService.showSuccess("Grower Created!");
      this.dialogRef.close(id)
    });

  }
}

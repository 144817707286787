<div class="stuff">
  <button class="button button--green-fill" (click)="draw()">Book New Isolation Pin</button>
  <mat-divider></mat-divider>
  <ng-container *ngIf="vm$ | async as viewModel">
    <p>Isolations for <b>{{viewModel[0].seedCompany.companyName}}</b> in <b>{{viewModel[1].harvestYear}}</b> </p>
  </ng-container>

  <mat-form-field  appearance="outline" subscriptSizing="dynamic" class="example-form-field">
    <mat-label>Filter</mat-label>
    <input matInput type="text" [formControl]="searchField" debounce="100">
    <button [hidden]="searchField.value == ''" matSuffix mat-icon-button aria-label="Clear" (click)="searchField.setValue('')">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <div class="order-by-container">
    <mat-form-field subscriptSizing="dynamic" appearance="outline">
      <mat-label>Order By</mat-label>
      <mat-select [formControl]="sortField" >
        <mat-option value="grower.businessName">Grower</mat-option>
        <mat-option value="status">Status</mat-option>
        <mat-option value="createdDate">Created Date</mat-option>
        <mat-option value="cropType.name">Crop Type</mat-option>
        <mat-option value="hasBreaches">Has Breaches</mat-option>
        <mat-option *ngIf="tokenService.hasAnyGivenRoles(['ADMINISTRATORS'])" value="seedCompany.companyName">Seed Company</mat-option>
      </mat-select>
      <button  [hidden]="sortField.value == ''" matSuffix mat-icon-button aria-label="Clear" (click)="sortField.setValue('')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-icon *ngIf="(sortOrder_ | async) as sortOrder" (click)="toggleSortDirection()">
      {{ sortOrder === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
    </mat-icon>

  </div>
  <mat-divider></mat-divider>
</div>

<app-isolations-virtual-list [items]="isolations$  | async"></app-isolations-virtual-list>


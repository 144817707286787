import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-isolation-confirmation',
  templateUrl: './create-isolation-confirmation.component.html',
  styleUrls: ['./create-isolation-confirmation.component.css']
})
export class CreateIsolationConfirmationComponent {
    isProduction = environment.production;
}

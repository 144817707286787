<div class="container">
  <div class="container_input">
    <input class="search_static" [(ngModel)]="searchText" type="text" placeholder="Enter Address" (keyup)="inputChanged()">
    <button (click)="inputChanged()" class="search_button search"><i class="fa fa-search"></i></button>
    <button (click)="clear()" class="search_button clear"><i class="fa fa-times"></i></button>
  </div>
  <ul class="suggestion_static">
    <li *ngFor="let result of suggestionList" (click)="search(result.text,result.magicKey)">{{result.text}}</li>
  </ul>
</div>

import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import LayerGroup from 'ol/layer/Group';
import TileLayer from 'ol/layer/Tile';
import { BehaviorSubject, Subject, pairwise, startWith, tap } from 'rxjs';
import { BasemapsService } from 'src/app/map/Basemaps.service';

@Component({
  selector: 'map-base-maps-select',
  templateUrl: './basemaps-select.component.html',
  styleUrls: ['./basemaps-select.component.css']
})
export class BasemapsSelectComponent implements OnInit {

  @Output() SelectedBaseMap = new EventEmitter<any>()

  public baseMapGroup: LayerGroup;

  private _layers$ = new BehaviorSubject<TileLayer<any>[]>([]);

  public layers;

  public selectedBaseMap = new FormControl();

  private readonly basemapService: BasemapsService = inject(BasemapsService);


  constructor()
  {

    this.selectedBaseMap.valueChanges.pipe(startWith(null), pairwise()).subscribe(([previousLayer, selectedLayer]) => {
      if (previousLayer) {
        previousLayer.setVisible(false);
      }
      selectedLayer.setVisible(true);
      this.SelectedBaseMap.emit(selectedLayer);
    });

    this.init();



  }

  async init()
  {

    this.layers = await this.basemapService.buildLayers();

    let defaultLayer;

    defaultLayer = this.layers.find(layer => layer.getVisible() == true);

    this.selectedBaseMap.setValue(defaultLayer);
  }

  ngOnInit(): void {

  }


}

<mat-drawer-container [hasBackdrop]="false" style="width: 100%; height:100%" class="example-container" >
  <mat-drawer  class="sidebar-drawer"  position="end" #sideNav mode="over" [opened]="route.firstChild" >
    <side-bar-header [sideNav]="sideNav" [pages]="sidebarService.getPages()" (openPage)="openPage($event)"></side-bar-header>
    <div class="router-container">
      <router-outlet></router-outlet>
  </div>
  </mat-drawer>

  <mat-drawer-content>
    <ng-content></ng-content>
    <ng-container *ngIf="{ isSmall: isSmallScreen$ | async } as screen">
      <div class="sidebar" [ngClass]="{'sidebar--bottom': screen.isSmall, 'sidebar--right': !screen.isSmall}" [style.display]="display$ | async">
  <ul>
    <li class="nav-item" *ngFor="let pair of sidebarService.getPages() | keyvalue"    [ngClass]="{
      'sidebar-page_active': pair.value.active,
      'last-item': isLast || pair.value.position == 'end'
    }" appTooltip [appTooltip]="pair.value.title"  [tooltipPosition]="screen.isSmall ? 'top' : 'left'"  (click)="openPage(pair.value);" ><i [class]="pair.value.className"></i></li>
  </ul>
      </div>
    </ng-container>
  </mat-drawer-content>

</mat-drawer-container>

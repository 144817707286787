import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'status'})
export class StatusPipe implements PipeTransform {

  statusMap = {
    1: 'Created',
    2: 'Paddock Created',
    3: 'Paddock Sown'
  }

  transform(value: number): string {
   return this.statusMap[value] || 'Unknown Status'
  }
}

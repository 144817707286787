
<div class="breach-info-header">
  <h2>Resolving a Breach</h2>
  <button class="button button--black-outline button--xs" (click)="close()">Close</button>
</div>
<mat-dialog-content class="mat-typography">
  <p>This pin is breach of {{ breach.breachCount | plural:'pin':'pins' }} from <mark>{{breach.seedCompany.companyName}}</mark> for <mark>{{breach.grower.businessName}}</mark></p>

  <p>To resolve the breach, first please contact <mark>{{breach.seedCompany.companyName}}</mark> via Phone: <mark>{{breach.seedCompany.contactPhone}}</mark><br>
  or Email:
  <br>
  <mark>{{breach.seedCompany.contactEmail}}</mark></p>

  <p>Once agreement has been reached, an admin from one or both companies will need to delete the pin for the location where sowing is no longer planned.</p>
  <p>Once one or both companies have deleted the pins within breach then the breach will be resolved.</p>
  <p hasRole [showIfHasRole]="['ADMINISTRATORS','OPERATIONS']">If this is you then you can delete this pin using the button below.</p>


</mat-dialog-content>

<mat-divider></mat-divider>
<mat-dialog-actions>
  <div class="admin-actions" hasRole [showIfHasRole]="['ADMINISTRATORS','OPERATIONS']">

    <button class="button button--danger button--xl button--equal-width" (click)="deleteIsolation()">Delete Isolation</button>
    <button class="button button--danger button--xl button--equal-width" (click)="resolveBreaches()">Resolve Breaches</button>
  </div>
</mat-dialog-actions>

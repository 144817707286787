import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss']
})
export class DeleteConfirmationComponent {

  constructor(public dialogRef: MatDialogRef<DeleteConfirmationComponent>,@Inject(MAT_DIALOG_DATA) public data: {type: string, details:string}) {

    if (!this.data) {
      this.data = {type: '{{entityType}}', details: '{{EntityDetails}}'};
  }
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}

import { trigger, state, style, transition, animate } from '@angular/animations';

export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('100ms', style({ opacity: 1 })),
  ]),
  transition(':leave', [
    animate('100ms', style({ opacity: 0 }))
  ])
]);

export const InOut2 =  trigger('flyInOut2', [
  state(':enter', style({ transform: 'translateX(-50%) translateY(0)' })),
  transition('void => *', [
    style({ transform: 'translateX(-50%) translateY(-100%)' }),
    animate(100)
  ]),
  transition('* => void', [
    animate(100, style({ transform: 'translateX(-50%) translateY(-100%)' }))
  ])
])



export const InOut =  trigger('flyInOut', [
    state(':enter', style({ transform: 'translateX(0)' })),
    transition('void => *', [
      style({ transform: 'translateX(-100%)' }),
      animate(100)
    ]),
    transition('* => void', [
      animate(100, style({ transform: 'translateX(-100%)' }))
    ])
  ])

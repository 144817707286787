<sidebar>
  <div #map class="map"></div>
  <div *ngIf="!isProduction" class="environment-overlay">
    Test System
  </div>
  <div #addAttributeOverlayHere></div>
  <div *ngIf="(isolationToolState$ | async) == false"
  id="esk-toolbar"
  class="esk-toolbar"
  [@flyInOut]
>
<map-zoom  [map]="mapService.getMap()"></map-zoom>
<map-zoom-area [map]="mapService.getMap()"></map-zoom-area>
<map-gps  [map]="mapService.getMap()" ></map-gps>
<map-measure  [map]="mapService.getMap()" ></map-measure>

</div>
<div *ngIf="!isProduction && (testSystemTermsAccepted$ | async) == false" class="test-warning-message">
  <h2>
    Test System
  </h2>
  <p class="test-warning-body">
    This system is used for evaluation purposes only. Any data entered here will not be reflected in the actual system.
  </p>
  <button class="test-warning-accept-button" (click)="onAcceptTestTerms()">I Understand</button>
  <span hasRole [showIfHasRole]="['ADMINISTRATORS']"  class="dont-show-again">
    <input type="checkbox" name="dontShowAgain" id="dontShowAgain" [formControl]="hideEnvironmentWarning">
    <label for="dontShowAgain" class="dont-show-again-message">Don't show this again</label>
  </span>
</div>

<app-isolation-toolbox-overlay  (disableTool)="isolationService.setDrawActive(false)" @fadeInOut  *ngIf="isolationToolState$ | async"></app-isolation-toolbox-overlay>
<app-geocode [map]="mapService.getMap()"></app-geocode>
</sidebar>


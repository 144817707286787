<div class="admin-sidebar">
<h2 *ngIf="isProduction; else testHeading">Admin</h2>
<ng-template #testHeading>
  <h2 class="test-heading">Test System</h2>
</ng-template>
<mat-divider></mat-divider>
<nav>
  <ul *ngIf="(isSmallScreen$ | async) === false">
    <li><a routerLink="users" class="admin-sidebar-link" routerLinkActive="router-link-active">Users</a></li>
    <li *ngIf="tokenService.hasAnyGivenRoles(['ADMINISTRATORS','OPERATIONS'])"><a routerLink="growers" class="admin-sidebar-link" routerLinkActive="router-link-active" >Growers</a></li>
    <li *ngIf="tokenService.hasAnyGivenRoles(['ADMINISTRATORS'])"><a routerLink="seed-companies" class="admin-sidebar-link" routerLinkActive="router-link-active">Seed Companies</a></li>
    <app-group></app-group>
  </ul>
  <!-- <div *ngIf="!isProduction" class="test-message-item">
    <ul>
      <li class="test-environment-message">
        Test System
      </li>
    </ul>
  </div> -->

  <ul *ngIf="(isSmallScreen$ | async) === false" class="admin-sidebar_user-actions">
    <mat-divider></mat-divider>

    <li><a routerLink="/map/isolations" class="admin-sidebar-link action" routerLinkActive="router-link-active"><mat-icon>map</mat-icon>Map</a></li>

    <li><button (click)="logout()" class="admin-sidebar-link action"><mat-icon>logout</mat-icon>Logout</button></li>
  </ul>



  <ng-container *ngIf="isSmallScreen$ | async">
    <button routerLink="/map/isolations" mat-icon-button>
      <mat-icon>map</mat-icon>
    </button>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>menu</mat-icon>
    </button>


    <mat-menu #menu="matMenu">
      <button mat-menu-item routerLink="users">Users</button>
      <button mat-menu-item routerLink="growers">Growers</button>
      <button *ngIf="tokenService.hasAnyGivenRoles(['ADMINISTRATORS'])" mat-menu-item routerLink="seed-companies">Seed Companies</button>
      <button mat-menu-item  [matMenuTriggerFor]="tokenService.hasAnyGivenRoles(['ADMINISTRATORS']) ? adminReferenceData : operationsReferenceData">Reference Data</button>
      <button mat-menu-item (click)="logout()">Logout<mat-icon>logout</mat-icon></button>
    </mat-menu>

    <mat-menu
    #adminReferenceData="matMenu">
      <button routerLink="reference-data/crop-types" mat-menu-item>Parent Codenames</button>
      <button  routerLink="reference-data/variety" mat-menu-item>Varieties</button>
      <button routerLink="reference-data/cultivar-colours" mat-menu-item>Cultivar Colours</button>
      <button  routerLink="reference-data/types" mat-menu-item>Types</button>
      <button routerLink="reference-data/seasons" mat-menu-item>Seasons</button>
      <button routerLink="reference-data/species" mat-menu-item>Species</button>
    </mat-menu>

    <mat-menu
    #operationsReferenceData="matMenu">
      <button mat-menu-item>Crop Types</button>
    </mat-menu>

  </ng-container>
</nav>
</div>

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CustomValidators } from 'src/app/_helpers/customValidators';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: UntypedFormGroup;
  error: string = null;
version: string;
  constructor(private route: ActivatedRoute, private authService: AuthenticationService, private toastService:ToastService) {

    this.version = environment.version;

     this.resetPasswordForm = new UntypedFormGroup({
      'password': new UntypedFormControl(null, [CustomValidators.passwordHasUpper, CustomValidators.minLength(8), CustomValidators.passwordHasSpecial, CustomValidators.required]),
      'repeatPassword': new UntypedFormControl(null,CustomValidators.required),
      'token': new UntypedFormControl(null,CustomValidators.required)
    },{validators: [CustomValidators.passwordsMatch('password','repeatPassword')]});

    this.resetPasswordForm.valueChanges.subscribe((changes) => {
      this.error = null;
    });


    this.route.params.subscribe((params) => {
      if (params['token']) {
         this.resetPasswordForm.get('token').setValue(params['token']);
      }
    });
  }

  ngOnInit(): void {

  }

  onSubmit(): void {
    this.authService.resetPassword({password: this.resetPasswordForm.get('password').value, repeatPassword:this.resetPasswordForm.get('repeatPassword').value},this.resetPasswordForm.get('token').value).subscribe({
      error: error => this.error = error,
      next: value =>  {this.toastService.showSuccess(value)}
    })
  }


}

export class CreateIsolationDTO {
  growerID: string;
  seasonID: string;
  cropTypeID: string;
  createdDate: Date;
  isolationGeom?: string;
  paddockGeom?: string;
  sownGeom?: string;
  isInvoiced: boolean = false;
  isPaid: boolean = false;
  isSown: boolean = false;
  sownDate?: Date;
  invoicedDate?: Date;
  paidDate?: Date;
  name:string;

  constructor(options: {
    growerID: string;
    seasonID: string;
    cropTypeID: string;
    createdDate?: Date;
    isolationGeom?: string;
    paddockGeom?: string;
    sownGeom?: string;
    isInvoiced?: boolean;
    isPaid?: boolean;
    isSown?: boolean;
    sownDate?: Date;
    invoicedDate?: Date;
    paidDate?: Date;
    name:string;
  }) {
    this.growerID = options.growerID;
    this.seasonID = options.seasonID;
    this.cropTypeID = options.cropTypeID;
    this.createdDate = options.createdDate;
    this.isolationGeom = options.isolationGeom;
    this.paddockGeom = options.paddockGeom;
    this.sownGeom = options.sownGeom;
    this.isInvoiced = options.isInvoiced || false;
    this.isPaid = options.isPaid || false;
    this.isSown = options.isSown || false;
    this.sownDate = options.sownDate;
    this.invoicedDate = options.invoicedDate;
    this.paidDate = options.paidDate;
    this.name = options.name;
  }
}

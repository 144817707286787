import { Component, inject } from '@angular/core';
import { SignalrService } from './shared/services/signalr.service';
import { AuthenticationService } from './shared/services/authentication/authentication.service';
import { OnlineStatusService, OnlineStatusType } from 'ngx-online-status';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'seed isolation';

  private readonly onlineStatusService: OnlineStatusService = inject(OnlineStatusService);

  constructor(authService: AuthenticationService) {


    authService.refreshToken().subscribe();

    this.onlineStatusService.status.subscribe((status: OnlineStatusType) => {

      if(status == OnlineStatusType.OFFLINE)
      return;

      authService.refreshToken().subscribe();
    });



  }
}

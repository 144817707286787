import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, switchMap, tap } from 'rxjs';
import { CreateCropTypeDTO } from './models/createCropType.model';
import { CropTypeDTO } from './models/cropType.model';
import { UpdateCropTypeDTO } from './models/updateCropType.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CropTypesService {

  private cultivars$ = new BehaviorSubject<Array<CropTypeDTO>>([]);

  private readonly endpoint =`${environment.apiUrl}/crop-types`;

  constructor(private httpClient: HttpClient)
  {
    this.getAll().subscribe();

  }

  getState() {
    return this.cultivars$.asObservable();
  }

  setState(data: Array<CropTypeDTO>) {

    this.cultivars$.next(data);
  }

  getAll()
  {
    return this.httpClient.get<Array<CropTypeDTO>>(this.endpoint).pipe(tap(data => this.setState(data)));
  }

  get(id)
  {
    return this.httpClient.get<CropTypeDTO>(this.endpoint + "/" + id)
  }

  create(model: CreateCropTypeDTO)
  {
    return this.httpClient.post(this.endpoint, model).pipe(tap(()=> this.getAll()));
  }

  update(id:string, model: UpdateCropTypeDTO)
  {
    return this.httpClient.put(`${this.endpoint}/${id}` , model).pipe(tap(()=> this.getAll()));
  }

  delete(id: string)
  {
      return this.httpClient.delete(`${this.endpoint}/${id}`).pipe(tap(data => this.getAll()));
  }

}

<h2 mat-dialog-title >Reset Password</h2>
<mat-dialog-content class="mat-typography"  style="padding-top: 1rem;">

  <form
    [formGroup]="passwordResetForm"
    (ngSubmit)="onSubmit()"
    class="grid grid--gap grid--row"

  >
  <mat-form-field appearance="outline">
    <mat-label>Password</mat-label>
    <input autocomplete="new-password" formControlName="password" type="password" matInput>
    <mat-error *ngIf="passwordResetForm.get('password').errors?.['required']">This field is required!</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Confirm password</mat-label>
    <input autocomplete="new-password" formControlName="rptPassword" type="password" matInput>
    <mat-error  *ngIf="!passwordResetForm.valid && passwordResetForm.get('password').touched  && passwordResetForm.get('rptPassword').touched">
      <span *ngIf="passwordResetForm.errors?.['notMatched']">Your passwords don't match</span>
     </mat-error>
  </mat-form-field>

  <app-password-complexity [passwordControl]="passwordResetForm.get('password')"></app-password-complexity>
  </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <footer>
      <button type="button" class="button button--black-outline" [mat-dialog-close]="false">
        Cancel
      </button>
      <button
        type="button"
        class="button button--blue"
  [disabled]="!passwordResetForm.valid"
        (click)="onSubmit()"
        type="submit"
      >
        Save
      </button>
    </footer>
  </mat-dialog-actions>

<h2 mat-dialog-title>History</h2>
<mat-dialog-content class="mat-typography">
<mat-table [dataSource]="data$ | async">

  <ng-container matColumnDef="ModifiedBy">
    <mat-header-cell *matHeaderCellDef>Modified By</mat-header-cell>
    <mat-cell *matCellDef="let auditEntry"> {{auditEntry.modifiedBy}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="ModifiedOn">
    <mat-header-cell *matHeaderCellDef>Modified On</mat-header-cell>
    <mat-cell *matCellDef="let auditEntry"> {{auditEntry.modifiedOn | date}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="OldValues">
    <mat-header-cell *matHeaderCellDef>Old Values</mat-header-cell>
    <mat-cell *matCellDef="let auditEntry"> {{auditEntry.oldValues | json}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="NewValues">
    <mat-header-cell *matHeaderCellDef>New Values</mat-header-cell>
    <mat-cell *matCellDef="let auditEntry"> {{auditEntry.newValues | json}} </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
    <button
      type="button"
      class="button button--blue"
      [mat-dialog-close]="true"
    >
      Close
    </button>
  </footer>
</mat-dialog-actions>

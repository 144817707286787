import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import {Group, Layer, Tile} from 'ol/layer';
import OlMap from 'ol/Map';
import { ProjectionLike } from "ol/proj";
import * as olProj from 'ol/proj';
import {

  OSM,

  Vector,

  Vector as VectorSource,


} from 'ol/source';
import View from "ol/View";
import { mapProjEPSG } from "src/app/_helpers/projection_setup";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import BaseLayer from "ol/layer/Base";
import LayerGroup from "ol/layer/Group";
import { populateLayerGroup } from 'src/app/_helpers/LayerBuilder';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Feature } from 'ol';
import { MultiPoint, Point } from 'ol/geom';
import { Draw, Modify, Snap } from 'ol/interaction';
import { ModifyEvent } from 'ol/interaction/Modify';
import RenderFeature from 'ol/render/Feature';
import { DrawEvent } from 'ol/interaction/Draw';
import { IsolationService } from '../../isolation.service';
import { IsolationDTO } from '../../models/isolation.model';
import { Difference, Intersect } from 'src/app/_helpers/transformations';
import { UntypedFormBuilder } from '@angular/forms';
import { Style, Fill, Stroke,Circle } from 'ol/style';
import { sownAreaStyle } from 'src/app/_helpers/layer.styles';
import { Attribution, ScaleLine } from 'ol/control';

@Component({
  selector: 'Sow-paddock',
  templateUrl: './sow-paddock.component.html',
  styleUrls: ['./sow-paddock.component.css']
})
export class SowPaddockComponent implements OnInit {

  @ViewChild('paddockMap', {static:true}) mapReference: ElementRef;
  private proj : ProjectionLike | null = olProj.get(mapProjEPSG);
  map: OlMap;

  private drawInteraction: Draw;
  private modifyInteraction: Modify;
  private drawLayer: VectorLayer<any>;
  private paddock: Feature<any> | RenderFeature;

  private paddockEditStyle = [ new Style({
    image: new Circle({
      radius: 5,
        fill: new Fill({
            color: 'rgb(0, 0, 255)'
        }),
      stroke: new Stroke({color: 'blue', width: 2})
    }),
    geometry: function(feature : Feature<any>) {
        var coordinates = feature.getGeometry().getCoordinates()[0];
        return new MultiPoint(coordinates);
    }
  }), new Style({
    stroke: new Stroke({
      color: [0, 112, 255, 1],
      width: 2,
    }),
    fill: new Fill({color:'rgba(0,125,255,0.25)'})
  })];



  public baseMapGroup = new LayerGroup({
    layers: [],
    title: 'BackgroundMaps',
    fold: 'open'
  } as any);




  constructor(
    private zone: NgZone,
    @Inject(MAT_DIALOG_DATA) private data:{isolation: IsolationDTO},
    public dialogRef: MatDialogRef<SowPaddockComponent>,
    private isolationService: IsolationService
    )
  {


    let isolationFeature = this.isolationService.featureMap.get(this.data.isolation.isolationID);
    let paddockFeature = this.isolationService.paddockMap.get(this.data.isolation.isolationID);


    const isolationLayer = new VectorLayer({source: new VectorSource({features: [isolationFeature]}),style: (feature, resolution) => {return this.isolationService.isolationPointStyle(feature,resolution,'')} });

    const paddockLayer = new VectorLayer({source: new VectorSource({features: [paddockFeature],},),style:this.paddockEditStyle});



    this.map = new OlMap({
      layers: [this.baseMapGroup,isolationLayer,paddockLayer],
      view: new View({
      center: isolationFeature.getGeometry().getCoordinates(),
      projection: this.proj == null ? null : this.proj,
      zoom: 13,
      maxZoom: 23,
    }),controls: [
      new Attribution(),
      new ScaleLine({
        bar: true,
        minWidth: 150
      })
    ],});

    this.drawLayer = new VectorLayer({
      source: new VectorSource(),
      style: sownAreaStyle
    });

    this.map.addLayer(this.drawLayer);

    // Create a draw interaction
    this.drawInteraction = new Draw({
      type: 'Polygon',
      source: this.drawLayer.getSource(),
      snapTolerance: 5,
      clickTolerance:0
    });

    let snap = new Snap(
      {
        source: paddockLayer.getSource()
      }
    );



    this.drawInteraction.on('drawend',async (event:DrawEvent) => {

      await this.clipFeature(event.feature, paddockFeature);

      this.map.removeInteraction(this.drawInteraction);
      this.map.addInteraction(this.modifyInteraction);
    })

    this.map.addInteraction(this.drawInteraction);
    this.map.addInteraction(snap);

    this.modifyInteraction = new Modify({source: this.drawLayer.getSource()});

    this.modifyInteraction.on('modifyend',async (event:ModifyEvent) => {

      let _feature = event.features.pop();

      if(_feature == undefined)
      {
        this.map.removeInteraction(this.modifyInteraction);
        this.map.addInteraction(this.drawInteraction);
      }



      await this.clipFeature(_feature, paddockFeature);
    })


  }

  private async clipFeature(feature, paddockFeature)
  {
    let clippedSownArea = await Intersect(feature, paddockFeature);

    this.drawLayer.getSource().clear();

    this.drawLayer.getSource().addFeature(clippedSownArea);

    this.paddock = clippedSownArea;
  }


  drawEnd(drawEvent)
  {

  }

  ngOnInit(): void {

    this.zone.runOutsideAngular(() => {
      this.map.setTarget(this.mapReference.nativeElement);
    })
  }

  onSubmit()
  {
    this.dialogRef.close(this.paddock);
  }


  onBaseMapChange(selectedLayer: any) {
    // Assuming 'map' is your ol/Map instance
      this.baseMapGroup.getLayers().clear();

      this.baseMapGroup.getLayers().push(selectedLayer);
  }


  clear()
  {
    let drawVectorSource = this.drawLayer.getSource() as VectorSource;

    drawVectorSource.clear();

  }

}


<mat-progress-bar mode="determinate" [value]="complexityProgress"></mat-progress-bar>
<mat-accordion>
<mat-expansion-panel >
<mat-expansion-panel-header>
<mat-panel-title [ngStyle]="{'color': colorMap.get(complexityProgress)}" >
Password Must Contain:
</mat-panel-title>
</mat-expansion-panel-header>
<ul style="padding: 0; margin:0;">
    <li [ngStyle]="{'color': passwordControl?.errors?.['noUpper'] ? 'red' : 'green'}">One or more uppercase characters.</li>
    <li [ngStyle]="{'color': passwordControl?.errors?.['noSpecial'] ? 'red' : 'green'}">One or more special characters e.g. !$&. </li>
    <li [ngStyle]="{'color': (passwordControl?.errors?.['minlength'] ||  passwordControl?.value == null || passwordControl?.value.length == 0 ) ? 'red' : 'green'}">8 or more characters.</li>
  </ul>
</mat-expansion-panel>
</mat-accordion>

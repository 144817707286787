import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, switchMap, tap } from "rxjs";
import { SeedCompanyDTO } from "./models/seed-company.model";
import { UpdateSeedCompanyDTO } from "./models/updateSeedCompany.model";
import { CreateSeedCompanyDTO } from "./models/create-seed-company.model";
import { environment } from "src/environments/environment";


@Injectable({
    providedIn: 'root',
  })
  export class SeedCompanyService {

    private seedCompanies$ = new BehaviorSubject<Array<SeedCompanyDTO>>([]);

    private readonly endpoint =`${environment.apiUrl}/seed-companies`;

    constructor(private httpClient: HttpClient)
    {
      this.getAll().subscribe();

    }

    getState() {
      return this.seedCompanies$.asObservable();
    }

    setState(seedCompanies: Array<SeedCompanyDTO>) {

      this.seedCompanies$.next(seedCompanies);
    }

    get(id)
    {
      return this.httpClient.get<SeedCompanyDTO>(`${this.endpoint}/${id}`);
    }


    getAll()
    {
      return this.httpClient.get<Array<SeedCompanyDTO>>(this.endpoint).pipe(tap(data => this.setState(data)));
    }

    create(seedCompany: CreateSeedCompanyDTO)
    {
      return this.httpClient.post(this.endpoint, seedCompany).pipe(switchMap(()=> this.getAll()));
    }

    update(id:string, model: UpdateSeedCompanyDTO)
    {
      return this.httpClient.put(`${this.endpoint}/${id}` , model).pipe(tap(()=> this.getAll()));
    }

    delete(id: string)
    {
        return this.httpClient.delete(`${this.endpoint}/${id}`).pipe(tap(data => this.getAll()));
    }

  }

import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from 'src/app/shared/services/users/users.service';
import { MapService } from 'src/app/map/map.service';
import { HeaderService } from 'src/app/map/sidebar/header/header.service';
import { TokenService } from 'src/app/shared/services/authentication/token.service';
import { GrowersService } from 'src/app/shared/services/growers/growers.service';
import { SeasonsService } from 'src/app/shared/services/seasons/seasons.service';
import { IsolationService } from '../../../isolation.service';
import { IsolationDTO } from '../../../models/isolation.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'isolation-form',
  templateUrl: './isolation-form.component.html',
  styleUrls: ['./isolation-form.component.css']
})
export class IsolationFormComponent implements OnChanges, OnInit {

  @Input() data: IsolationDTO;
  @Input() breaches;


  displayedColumns: string[] = ['growerName', 'businessName', 'breachCount'];

  constructor(
    private isolationService:IsolationService,
    public userService: UsersService,
    public tokenService:TokenService,
    public growerService:GrowersService,
    public seasonService:SeasonsService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private mapService:MapService,
    private headerService:HeaderService) {
    }


  form: FormGroup;

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['data'] && changes['data'].currentValue) {
      this.form.patchValue(changes['data'].currentValue);
    }

  }

  ngOnInit(): void {

    this.form = new FormGroup({
      'isSown': new FormControl(),
      'userID': new FormControl({value: '', disabled: !this.tokenService.isAdmin()}),
      'growerID': new FormControl({value: '', disabled: !this.tokenService.isAdmin()}),
      'seasonID': new FormControl({value: '', disabled: !this.tokenService.isAdmin()}),
      'sownDate': new FormControl(),
      'isInvoiced': new FormControl(),
      'invoicedDate': new FormControl(),
      'isPaid': new FormControl(),
      'paidDate': new FormControl(),
      'paddockGeom': new FormControl(),
      'isolationID': new FormControl()
    });

              this.form.get('isSown').valueChanges.subscribe(sown => {
                if (sown) {
                  this.form.get('sownDate').setValidators(Validators.required);
                } else {
                  this.form.get('sownDate').clearValidators();
                }
                this.form.get('sownDate').updateValueAndValidity();
              });

  }

}

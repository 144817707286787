import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'resolved-breach-list',
  templateUrl: './resolved-breach-list.component.html',
  styleUrls: ['./resolved-breach-list.component.css']
})
export class ResolvedBreachListComponent {

  @Input() resolvedBreaches: any[] | null = [];

  @Output() breachClicked = new EventEmitter();

}



<section class="breaches">
  <div class="breaches-header">
   <h2>Resolved Breaches</h2>
  </div>
  <mat-divider></mat-divider>
  <div class="breaches-container" *ngIf="resolvedBreaches">
    <ul *ngIf="resolvedBreaches?.length > 0; else noBreaches">
      <li class="breach-item" *ngFor="let breach of resolvedBreaches" (click)="breachClicked.emit(breach)">
        <a>
          {{ breach.breachCount | plural:'breach':'breaches' }} with {{breach.seedCompany.companyName}}
        </a>
      </li>
    </ul>

    <ng-template #noBreaches>
      <div class="breachs-empty">
        <span>No resolved breaches</span>
      </div>
    </ng-template>
  </div>

  <mat-divider></mat-divider>
</section>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin.component';
import { CreateUserComponent } from './users/create-user/create-user.component';
import { UserManagementComponent } from './users/user-management/user-management.component';
import { RoleGuard } from '../shared/guards/role.guard';

const routes: Routes = [
  {
    path: '', component: AdminComponent, children:
    [
      {
        path: '',
        redirectTo: 'users',
        pathMatch: 'full',
      },
      { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule)},
      { path: 'seed-companies', loadChildren: () => import('./seed-companies/seed-companies.module').then(m => m.SeedCompaniesModule), canActivate:[RoleGuard], data: { roles: ['ADMINISTRATORS'] }},
      { path: 'growers', loadChildren: () => import('./growers/growers.module').then(m => m.GrowersModule), canActivate:[RoleGuard], data: { roles: ['ADMINISTRATORS','OPERATIONS'] }},
      { path: 'reference-data', loadChildren: () => import('./reference-data/reference-data.module').then(m => m.ReferenceDataModule)}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }

<section style="padding:1rem; display: grid; gap:1rem;">
  <main style="display: grid; gap:1rem;">

    <ng-template ngIf="historicalLayersLoaded$">
      <app-layer-group *ngFor="let item of archivedSeasons|async" [layerGroup]="mapService.getLayerGroup('id',item.seasonID)"></app-layer-group>
    </ng-template>
    <app-layer [layer]="mapService.getLayer('Non-Traditional Carrot Area')"></app-layer>
    <app-base-group  *ngIf=" basemapsLoaded$ | async" [layerGroup]="mapService.getLayerGroup('title','Background Maps')"></app-base-group>

  </main>
 </section>

<h1 mat-dialog-title>Resolve Breach</h1>

<mat-dialog-content class="mat-typography">
  <p>Are you sure you want to resolve these Breaches?</p>
  <p>Please enter a comment detailing how this breach was resolved, this will be shared via email to the other parties involved.</p>
  <mat-form-field class="full-width">
    <textarea resize="none" matInput  placeholder="Please enter comment" [formControl]="commentControl" required></textarea>
    <mat-error *ngIf="commentControl.hasError('required')">Comment is required</mat-error>
  </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions class="actions" align="end">
  <button class="button button--muted button--xs" (click)="onCancelClick()">Cancel</button>
  <button class="button button--danger button--xs" [disabled]="!commentControl.valid" (click)="submit()">Yes</button>
</mat-dialog-actions>

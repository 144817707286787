import { Component, EventEmitter, Input, Output } from '@angular/core';
import VectorLayer from 'ol/layer/Vector';

@Component({
  selector: 'map-clear-layer',
  templateUrl: './clear-layer.component.html',
  styleUrls: ['./clear-layer.component.css']
})
export class ClearLayerComponent {

  @Output() Clear = new EventEmitter()


  clear () {
      this.Clear.emit();
  }

}

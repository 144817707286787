
<h2 mat-dialog-title>Crop</h2>
<mat-dialog-content class="mat-typography">
  <div #chart class="overflow-y-auto"></div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
    <button
      type="button"
      class="button button--blue"
      [mat-dialog-close]="true"
    >
      Close
    </button>
  </footer>
</mat-dialog-actions>

import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomValidators } from 'src/app/_helpers/customValidators';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent {

passwordResetForm: UntypedFormGroup;

constructor(public dialogRef: MatDialogRef<PasswordResetComponent>) {
  this.passwordResetForm = new UntypedFormGroup({
    'password': new UntypedFormControl(null, [CustomValidators.passwordHasUpper, CustomValidators.minLength(8), CustomValidators.passwordHasSpecial, CustomValidators.required]),
    'rptPassword': new UntypedFormControl(null,CustomValidators.required),},{validators: [CustomValidators.passwordsMatch('password','rptPassword')]});
}

onSubmit()
{
  this.dialogRef.close(this.passwordResetForm.get('password').value);
}


}

import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild, inject } from '@angular/core';
import {Group, Layer, Tile} from 'ol/layer';
import OlMap from 'ol/Map';
import { ProjectionLike } from "ol/proj";
import * as olProj from 'ol/proj';
import {

  OSM,

  Vector,

  Vector as VectorSource,


} from 'ol/source';
import View from "ol/View";
import { mapProjEPSG } from "src/app/_helpers/projection_setup";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import BaseLayer from "ol/layer/Base";
import LayerGroup from "ol/layer/Group";
import { populateLayerGroup } from 'src/app/_helpers/LayerBuilder';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Feature } from 'ol';
import {  MultiPoint, Point, Polygon } from 'ol/geom';
import { Draw, Modify } from 'ol/interaction';
import { ModifyEvent } from 'ol/interaction/Modify';
import RenderFeature from 'ol/render/Feature';
import { DrawEvent } from 'ol/interaction/Draw';
import { Style, Fill, Stroke,Circle } from 'ol/style';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { tap } from 'rxjs';
import { IsolationService } from '../../isolation.service';
import { Attribution, ScaleLine } from 'ol/control';

@Component({
  selector: 'app-edit-paddock',
  templateUrl: './edit-paddock.component.html',
  styleUrls: ['./edit-paddock.component.css']
})
export class EditPaddockComponent  implements OnInit {

  @ViewChild('paddockMap', {static:true}) mapReference: ElementRef;


  private proj : ProjectionLike | null = olProj.get(mapProjEPSG);
  map: OlMap;

  private drawInteraction: Draw;
  private modifyInteraction: Modify;
  private modifyLayer: VectorLayer<any>;
  private paddock: Feature<any> | RenderFeature;


  public baseMapGroup = new LayerGroup({
    layers: [],
    title: 'BackgroundMaps',
    fold: 'open'
  } as any);



  constructor(
    private zone: NgZone,
    @Inject(MAT_DIALOG_DATA) private data:{feature:Feature<Polygon>},
    public dialogRef: MatDialogRef<EditPaddockComponent>,
    private isolationService: IsolationService
    )
  {


var paddockEditStyle = [ new Style({
  image: new Circle({
    radius: 5,
      fill: new Fill({
          color: 'rgb(0, 0, 255)'
      }),
    stroke: new Stroke({color: 'blue', width: 2})
  }),
  geometry: function(feature : Feature<any>) {
      var coordinates = feature.getGeometry().getCoordinates()[0];
      return new MultiPoint(coordinates);
  }
}), new Style({
  stroke: new Stroke({
    color: [0, 112, 255, 1],
    width: 2,
  }),
  fill: new Fill({color:'rgba(0,125,255,0.25)'})
})];


var originalPaddockStyle = [ new Style({
  image: new Circle({
    radius: 5,
      fill: new Fill({
          color: 'rgb(255, 0, 0)'
      }),
    stroke: new Stroke({color: 'red', width: 2})
  }),
  geometry: function(feature : Feature<any>) {
      var coordinates = feature.getGeometry().getCoordinates()[0];
      return new MultiPoint(coordinates);
  }
}), new Style({
  stroke: new Stroke({
    color: [255, 0, 0, 1],
    width: 2,
  }),
  fill: new Fill({color:'rgba(255,0,125,0.25)'})
})];


  const isolationLayer = new VectorLayer({source: new VectorSource({features: [this.data.feature]}),style:originalPaddockStyle})

    this.map = new OlMap({
      layers: [this.baseMapGroup,isolationLayer],

      view: new View({
      center: data.feature.getGeometry().getExtent(),
      projection: this.proj == null ? null : this.proj,
      zoom: 13,
      maxZoom: 23,
    }),
    controls: [
      new Attribution(),
      new ScaleLine({
        bar: true,
        minWidth: 150
      })
    ],});

    this.modifyLayer = new VectorLayer({
      source: new VectorSource({features:[this.data.feature.clone()]}),
      style:paddockEditStyle
    });

    this.map.addLayer(this.modifyLayer);

    this.modifyInteraction = new Modify({source: this.modifyLayer.getSource()});

    this.modifyInteraction.on('modifyend',(event:ModifyEvent) => {

      let _feature = event.features.pop();

      this.paddock = _feature == undefined ? this.paddock : _feature;
    })


    this.map.addInteraction(this.modifyInteraction);


    this.map.on('rendercomplete', (e) => {
      this.map.updateSize();
    });
  }

  ngOnInit(): void {


    this.zone.runOutsideAngular(() => {
      this.map.setTarget(this.mapReference.nativeElement);
    });

    this.map.getView().fit(this.data.feature.getGeometry(), {duration:2000, maxZoom:12});
  }

  onSubmit()
  {
    this.dialogRef.close(this.paddock);
  }

  onBaseMapChange(selectedLayer: any) {
    // Assuming 'map' is your ol/Map instance
      this.baseMapGroup.getLayers().clear();

      this.baseMapGroup.getLayers().push(selectedLayer);
  }


  clear()
  {
    let drawVectorSource = this.modifyLayer.getSource() as VectorSource;

    drawVectorSource.clear();

    drawVectorSource.addFeature(this.data.feature.clone())
  }

}


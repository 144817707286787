import { Component, Inject, Input, OnInit, inject } from '@angular/core';
import { AuditService } from '../../../services/audit/audit.service';
import { Observable } from 'rxjs';
import { AuditEntryDTO } from '../../../services/audit/models/audit.entry.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'audit-entry',
  templateUrl: './audit-entry.component.html',
  styleUrls: ['./audit-entry.component.css']
})
export class AuditEntryComponent implements OnInit {

  private readonly auditService:AuditService = inject(AuditService);

  displayedColumns: string[] = ['ModifiedBy', 'ModifiedOn', 'OldValues','NewValues'];

  data$: Observable<AuditEntryDTO[]>;

  constructor( @Inject(MAT_DIALOG_DATA) private data:{entityID},) {


  }

  ngOnInit(): void {
    this.data$ = this.auditService.get(this.data.entityID)
  }


}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuditEntryDTO } from './models/audit.entry.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  private users$ = new BehaviorSubject<Array<AuditEntryDTO>>([]);

  private readonly endpoint = `${environment.apiUrl}/audit`;

  constructor(private httpClient: HttpClient)
  {

  }

  get(id:string)
  {
    return this.httpClient.get<Array<AuditEntryDTO>>(`${this.endpoint}/${id}`)
  }
}

import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as d3 from 'd3';
import { Feature } from 'ol';
import { Polygon } from 'ol/geom';

@Component({
  selector: 'app-relational-diagram-component',
  templateUrl: './relational-diagram-component.component.html',
  styleUrls: ['./relational-diagram-component.component.css']
})
export class RelationalDiagramComponentComponent {
  @ViewChild('chart',{static:true})
  private chartContainer: ElementRef;



  constructor( @Inject(MAT_DIALOG_DATA) private data,) { }

  ngOnInit() {
    let formatted = this.reformatData(this.data)
    this.createChart(formatted);
  }

   reformatData(data) {
    return  this.createCropSection(data);
  }



   createParentSection(parent, title) {
    if (!parent) return null;
    return {
      name: parent.name,
      description: parent.description,
      title: title,
      children: [
        {
          name: parent.species.name,
          description: parent.species.description,
          title: "Species",
          children: [],
        },
        {
          name: parent.cultivarColour.name,
          description: "",
          title: "Cultivar Colour",
          children: [],
        },
      ],
    };
  }

   createVarietySection(variety) {
    const maleParentSection = this.createParentSection(variety.maleParent, "Male Parent");
    const femaleParentSection = this.createParentSection(variety.femaleParent, "Female Parent");

    return {
      name: variety.name,
      title: "Variety",
      description: '',
      children: [
        maleParentSection,
        ...(femaleParentSection ? [femaleParentSection] : []),
      ].filter(Boolean),
    };
  }

   createCropSection(data) {
    return {
      name: data.name,
      description: data.description,
      title: "Code Name",
      children: [
        this.createVarietySection(data.variety),
      ],
    };
  }



  createChart(data) {
    let element = this.chartContainer.nativeElement;

    const svg = d3.select(element)
      .append("svg")
      .attr("width", 800)
      .attr("height", 800)
      .append("g")
      .attr("transform", "translate(0, 40)");

    const tree = d3.tree().size([800, 600])
    .separation(function(a, b) {
      return a.parent == b.parent ? 1.5 : 2;
  });
    const root = d3.hierarchy(data);
    tree(root);



  // Create links
  const link = svg.selectAll('.link')
  .data(root.links())
  .enter().append('path')
  .attr('class', 'link')
  .attr('d', d3.linkVertical()
    .x(d => d.x)
    .y(d => d.y)
  );


  // Create nodes
  const node = svg.selectAll('.node')
    .data(root.descendants())
    .enter().append('g')
    .attr('class', function(d) {
      return "node" + (d.children ? " node--internal" : " node--leaf"); })
    .attr('transform', function(d) { return `translate(${d.x},${d.y})` });

    let rect = node.append("rect")
    .attr("class", "node-rect")
    .attr('fill', '#F0F0F0')
    .attr('stroke', '#A6ACAF');

  // Append title text to rectangles
  let title = node.append('text')
    .attr('dy', '-1.5em')  // Adjust according to your needs
    .attr('text-anchor', 'middle')
    .text(function(d) { return `${d.data.title}`; });  // Assuming that the title data exists

  // Append name text to rectangles
  let text = node.append('text')
    .attr('text-anchor', 'middle')
    .text(function(d) { return `Name: ${d.data.name}`; });

  // Append description text to rectangles
  // text.append('tspan')
  //   .attr('dy', '1em')
  //   .attr('x', 0)
  //   .text(function(d) { return `Description: ${d.data.description}`; });


// Select elements with the class 'node-rect'
node.select('.node-rect')
    // Adjust the 'x' attribute of each selected element
    .attr('x', function(d) {
      // Calculate the width of the associated text element
      let textWidth = this.nextSibling.nextSibling.getBBox().width;

      // Set the 'x' position to align the center of the rectangle with the center of the text
      // and add a 10-unit padding on the left side
      return -textWidth / 2 - 10;
    })
    // Adjust the 'y' attribute of each selected element
    .attr('y', function(d) {
      // Calculate the height of the associated text element
      let textHeight = this.nextSibling.nextSibling.getBBox().height;

      // Set the 'y' position to align the center of the rectangle with the center of the text
      // and add a 5-unit padding on the top side
      return -textHeight / 2 - 5;
    })
    // Adjust the 'width' attribute of each selected element
    .attr('width', function(d) {
      // Calculate the width of the associated text element
      let textWidth = this.nextSibling.nextSibling.getBBox().width;

      // Set the width of the rectangle to the width of the text plus 20 units for padding
      return textWidth + 20;
    })
    // Adjust the 'height' attribute of each selected element
    .attr('height', function(d) {
      // Calculate the height of the associated text element
      let textHeight = this.nextSibling.nextSibling.getBBox().height;

      // Set the height of the rectangle to the height of the text plus 10 units for padding
      return textHeight + 10;
    });

  text.attr('dy', '0.35em');


  }
}


import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, inject } from '@angular/core';
import { MapService } from '../map.service';
import { Draw, Select, Snap } from 'ol/interaction';
import {DrawType} from 'src/app/_helpers/enums'
import VectorSource from 'ol/source/Vector';
import { Control } from 'ol/control';
import { IsolationService } from './isolation.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateIsolationDialogComponent } from './dialogs/create-isolation-dialog/create-isolation-dialog.component';
import { BreachInfoComponent } from './dialogs/breach-info/breach-info.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, combineLatest, map, observable, startWith, tap } from 'rxjs';
import  { unByKey } from 'ol/Observable';
import { HeaderService } from '../sidebar/header/header.service';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { SeasonsService } from 'src/app/shared/services/seasons/seasons.service';
import { FormControl } from '@angular/forms';
import { Subject } from '@microsoft/signalr';
import { TokenService } from 'src/app/shared/services/authentication/token.service';
import { ErrorComponent } from 'src/app/shared/components/dialogs/error/error.component';
import { EventsKey } from 'ol/events';
import { Map } from 'ol';
import { ModalService } from 'src/app/shared/services/modal.service';
import { UserProfileService } from '../user-profile/user-profile.service';

@Component({
  selector: 'app-isolations',
  templateUrl: './isolations.component.html',
  styleUrls: ['./isolations.component.scss']
})
export class IsolationsComponent implements OnInit,AfterViewInit, OnDestroy{


  private readonly authenticationService: AuthenticationService = inject(AuthenticationService);
  private readonly userProfileService: UserProfileService = inject(UserProfileService);
  private readonly seasonService: SeasonsService = inject(SeasonsService);
  public readonly tokenService: TokenService = inject(TokenService);
  private readonly modalService:ModalService = inject(ModalService);

  private vectorSource: VectorSource<any> = new VectorSource();
  private interaction: Draw;
  private control: Control;
  private eventKey: EventsKey;
  public isActive: boolean = false;
  private allowed = true;
  private select: Select;
  private isActiveSubscription: Subscription;
  public vm$;
  public searchField;
  public sortField;
  private map:Map;

  public sortOrder: BehaviorSubject<'asc' | 'desc'> = new BehaviorSubject('desc');
  public sortOrder_: Observable<'asc' | 'desc'> = this.sortOrder.asObservable();

  public isolations$;


  constructor(private mapService:MapService,private elRef:ElementRef, public isolationService: IsolationService, private zone: NgZone,
    private router:Router) {


      this.vm$ = combineLatest([this.userProfileService.get(),  this.seasonService.getCurrentSeason().pipe(
        map(seasons => seasons.find(season =>season.isActive === true)))]);

        this.map = mapService.getMap();


      this.searchField = new FormControl('');
      this.sortField = new FormControl('');

  this.isActiveSubscription = this.isolationService.isDrawActive.subscribe((isActive) => {
    if(isActive) {
      this.activateDrawingMode();
    } else {
     this.deactivateDrawingMode();
    }
  });


 const defaultIsolations$ = this.isolationService.getState().pipe(
  map(isolations => isolations.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()))
);

const searchTerm$:Observable<string> = this.searchField.valueChanges.pipe(
  startWith(this.searchField.value),
);

const sortField$:Observable<string> = this.sortField.valueChanges.pipe(
  startWith(this.searchField.value),
);

this.isolations$ = combineLatest([
  defaultIsolations$,
  searchTerm$,
  sortField$,
  this.sortOrder_
]).pipe(
  map(([isolations, searchTerm, sortField, sortOrder]) => {
    let filtered = isolations.filter(
      (isolation) =>
        searchTerm === '' ||
        isolation.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        isolation.grower.businessName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        isolation.grower.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        isolation.seedCompany.companyName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (sortField !== '') {
      filtered.sort((a, b) => {
        let valueA, valueB;

        if (sortField.includes('.')) {
          let fields = sortField.split('.');
          valueA = a[fields[0]][fields[1]];
          valueB = b[fields[0]][fields[1]];
        } else {
          valueA = a[sortField];
          valueB = b[sortField];
        }

        if(sortField == 'createdDate')
        {
          valueA = new Date(valueA);
          valueB = new Date(valueB);
        }

        let compareResult;

        if (typeof valueA === 'number' && typeof valueB === 'number') {
          // compare numbers
          compareResult = valueA - valueB;
        } else if (valueA instanceof Date && valueB instanceof Date) {
          // compare dates
          compareResult = valueA.getTime() - valueB.getTime();
        } else if (typeof valueA === 'string' && typeof valueB === 'string') {
          // compare strings
          compareResult = valueA.localeCompare(valueB);
        }
        else if (typeof valueA === 'boolean' && typeof valueB === 'boolean') {
          compareResult = Number(valueA) - Number(valueB)
        }
         else {
          // different types or undefined values, do not change order
          compareResult = 0;
        }

        return sortOrder === 'asc' ? compareResult : -compareResult;
      });
    }

    return filtered;
  }),
);

  }



activateDrawingMode() {
  this.map.addInteraction(this.interaction);
  this.mapService.disableSelect();
  this.registerPointerMoveEvent();
}


deactivateDrawingMode() {
  this.map.removeInteraction(this.interaction);
  this.resetCursorToDefault();
  this.mapService.enableSelect();
}


registerPointerMoveEvent() {
  this.eventKey = this.map.on('pointermove', event => {
      this.zone.run(() => {
          this.pointerMoveEvent(event);
      });
  });
}


resetCursorToDefault() {
  unByKey(this.eventKey);
  const targetElement = this.map.getTargetElement();
  if (targetElement) {
      targetElement.style.cursor = 'default';
  }
}

  ngOnInit(): void {
    this.control = new Control({target: this.elRef.nativeElement.parentNode, element: this.elRef.nativeElement});

    this.interaction = new Draw({
      source: this.vectorSource,
      type: DrawType.Point,
      snapTolerance: 20,

    });


    this.map.addControl(this.control);


    this.interaction.on('drawend', (e)=> {
      if(!this.allowed)
      {
            return;
      }
      this.zone.run( () => {

      this.modalService.showBookIsolation(e.feature)
      .subscribe(result => {

        if(!result)
        return;

        this.isolationService.add(result).pipe(tap(value => {this.draw()})).subscribe(
          {
            next:  isolationID =>
            {
              this.router.navigate(['/map/isolations', isolationID]);
            },
            error: (message:Error) => this.modalService.showError(message).subscribe()
          }

          );
      })
    });
    })
  }

  ngOnDestroy(): void {
    this.isActiveSubscription.unsubscribe();
    this.isolationService.setDrawActive(false);
    this.map.removeInteraction(this.interaction);
    this.map.removeInteraction(this.select);
    this.map.removeControl(this.control);
  }

  ngAfterViewInit(): void {

  }

  private pointerMoveEvent(e)
  {
    if (this.mapService.withinTasmania(e.coordinate)){
      this.map.getTargetElement().style.cursor = 'default';
      this.allowed = true;
    } else {
      this.allowed = false;
      this.map.getTargetElement().style.cursor = 'not-allowed';
    }
  }

  draw() {
    // Toggle the isActive value in the service
    this.isolationService.toggleDrawActive();
  }

  toggleSortDirection() {
    this.sortOrder.next(this.sortOrder.value === 'asc' ? 'desc' : 'asc');
  }


}

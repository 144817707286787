import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-crop-failed-dialog',
  templateUrl: './crop-failed-dialog.component.html',
  styleUrls: ['./crop-failed-dialog.component.css']
})
export class CropFailedDialogComponent {

  commentControl = new FormControl('', [Validators.required]);

  constructor(public dialogRef: MatDialogRef<CropFailedDialogComponent>,) {

  }

    onCancelClick(): void {
      this.dialogRef.close();
    }

    confirm() {
      if (this.commentControl.valid) {
        this.dialogRef.close({comment: this.commentControl.value});
      } else {
        this.commentControl.markAsTouched();
      }
    }

}
